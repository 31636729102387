import * as Sentry from '@sentry/react';
import {
  take,
  takeEvery,
  takeLatest,
  put,
  call,
  race,
  select,
} from 'redux-saga/effects';
import { BroadcastChannel as MeetingBroadcastChannel } from 'broadcast-channel';
import {
  MEETING_ENDED,
  MEETING_STARTING,
  MEETING_JOINED,
  meetingJoined,
  MEETING_HANG_UP_REQUESTED,
  meetingEnded,
  SHOW_MEETING,
  MEETING_AUDIO_MUTED,
  audioMuted,
  videoMuted,
  screenShared,
  MEETING_SCREEN_SHARED,
  MEETING_VIDEO_MUTED,
  meetingLeft,
  MEETING_LEFT,
  meetingActiveHeartbeat,
  MEETING_ACTIVE_HEARTBEAT,
  meetingInvitationRejected,
  MEETING_STATUS,
} from '.';
import { eventChannel } from 'redux-saga';
import { getMeetingPopupUrl, isDesktopApp } from 'config/config';
import {
  getMeeting,
  getMeetingStatus,
  getIsChannelMeetingByRoomId,
  isInviteEnabledByRoomId,
  isRemoteDesktopEnabledByRoomId,
} from 'store/Meeting/selectors';

function createWindowMessageListenerChannel() {
  return eventChannel(emitter => {
    const onMessage = message => {
      emitter(message);
    };
    const meetingBroadcastChannel = new MeetingBroadcastChannel('meeting');
    meetingBroadcastChannel.addEventListener('message', onMessage);
    return () => {
      meetingBroadcastChannel.removeEventListener('message', onMessage);
      meetingBroadcastChannel.close();
    };
  });
}

export const MEETING_POPUP_CLOSED = 'MEETING_POPUP_CLOSED';

export function openMeetingWindow(url) {
  if (isDesktopApp()) {
    return null;
  }
  const popupHeight = window.screen.availHeight / 2;
  const popupWidth = window.screen.availWidth / 2;
  const popupLeft = window.screen.availWidth / 2 - popupWidth / 2;
  const popupTop = window.screen.availHeight / 2 - popupHeight / 2;

  return window.open(
    url,
    'we-team-meeting-window',
    `width=${popupWidth},height=${popupHeight},left=${popupLeft},top=${popupTop},location=no`
  );
}

function* meetingPopupClosed(roomId) {
  const meetingStatus = yield select(getMeetingStatus);
  if (
    meetingStatus === MEETING_STATUS.JOINED ||
    meetingStatus === MEETING_STATUS.HANG_UP_REQUESTED
  ) {
    yield put(meetingLeft(roomId));
  } else if (meetingStatus === MEETING_STATUS.STARTING) {
    yield put(meetingInvitationRejected(roomId));
  }
  yield put(meetingEnded());
}

export function* startMeeting(action) {
  try {
    const meeting = yield select(getMeeting, action.roomId);
    const isMeetingInChannel = yield select(
      getIsChannelMeetingByRoomId,
      action.roomId
    );

    const remoteDesktopEnabled = yield select(
      isRemoteDesktopEnabledByRoomId,
      meeting.roomId
    );

    const inviteEnabled = yield select(isInviteEnabledByRoomId, meeting.roomId);

    let meetingWindow;
    const meetingPopupUrl = getMeetingPopupUrl({
      roomId: meeting.roomId,
      meetingServerUrl: meeting.meetingServerUrl,
      remoteDesktopEnabled,
      inviteEnabled,
      callerName: meeting.caller?.firstName,
      channelName: isMeetingInChannel ? meeting.channelName : null,
      lobbyEnabled: action.lobbyEnabled,
      password: action.password,
    });
    if (isDesktopApp()) {
      window.ipcRenderer?.send('open-meeting-popup', meetingPopupUrl);
    } else {
      meetingWindow = openMeetingWindow(meetingPopupUrl);
    }

    window.addEventListener('beforeunload', () => {
      if (meetingWindow) {
        meetingWindow.close();
      }
    });

    const popupChannel = yield call(createWindowMessageListenerChannel);
    try {
      yield takeEvery(popupChannel, function* (message) {
        if (message.type === MEETING_JOINED) {
          yield put(meetingJoined(action.roomId, message.localParticipantId));
        } else if (message.type === MEETING_ENDED) {
          yield put(meetingEnded());
        } else if (message.type === MEETING_AUDIO_MUTED) {
          yield put(audioMuted(message.muted));
        } else if (message.type === MEETING_VIDEO_MUTED) {
          yield put(videoMuted(message.muted));
        } else if (message.type === MEETING_SCREEN_SHARED) {
          yield put(screenShared(message.isScreenShared));
        } else if (message.type === MEETING_POPUP_CLOSED) {
          yield meetingPopupClosed(action.roomId);
        } else if (message.type === MEETING_LEFT) {
          yield put(meetingLeft(action.roomId));
        } else if (message.type === MEETING_ACTIVE_HEARTBEAT) {
          yield put(meetingActiveHeartbeat(action.roomId));
        }
      });
      yield takeEvery(SHOW_MEETING, function () {
        if (isDesktopApp()) {
          window.ipcRenderer?.send('focus-meeting-popup');
        } else {
          meetingWindow.focus();
        }
      });

      /*
        wait for an ended event or a hang up requested event
      */
      const { hangUp } = yield race({
        ended: take(MEETING_ENDED),
        hangUp: take(MEETING_HANG_UP_REQUESTED),
      });

      if (hangUp) {
        yield meetingPopupClosed(action.roomId);
        if (isDesktopApp()) {
          window.ipcRenderer?.send('close-meeting-popup');
        } else {
          meetingWindow?.close();
        }
      }
    } finally {
      popupChannel.close();
    }
  } catch (error) {
    console.error(error); /* eslint-disable-line */
    Sentry.captureException(error);
  }
}

export default function* meetingPopupSaga() {
  yield takeLatest(MEETING_STARTING, startMeeting);
}
