import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router';

import { getNotificationLink } from 'pages';
import { FormattedMessage } from 'react-intl';

import { makeGetSpaceById } from 'store/Spaces/selectors';
import { useWorkspace } from 'Hooks/data/workspace/useWorkspace';
import { getResourceById } from 'store/Resources/selectors';

function AudioPlayerTitle({ channelId, resourceId, notificationId }) {
  const getSpaceById = useMemo(makeGetSpaceById, []);
  const channel = useSelector(state => getSpaceById(state, channelId));
  const workspace = useWorkspace(channel.WorkspaceId);
  const resource = useSelector(state => getResourceById(state, resourceId));

  return (
    <>
      <FormattedMessage id="AudioPlayer.Title" />
      &nbsp;
      <Link to={getNotificationLink(channelId, notificationId)}>
        {/* eslint-disable-next-line react/jsx-no-literals */}
        {workspace.Name} > {channel.Name} > {resource.Name}
      </Link>
    </>
  );
}

AudioPlayerTitle.propTypes = {
  notificationId: PropTypes.string,
  channelId: PropTypes.string,
  resourceId: PropTypes.string,
};

export default AudioPlayerTitle;
