import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import { Dropdown, Popup } from 'semantic-ui-react';
import ShowIfNotAppStore from 'Components/Electron/ShowIfNotAppStore';
import { getPrivacyPolicyUrl, getSupportEmailMailToLink } from 'config/config';
import { connect } from 'react-redux';
import { getLocale } from 'store/Locale/selectors';
import {
  showChatFlow,
  showFilesFlow,
  showIntroFlow,
  showMeetFlow,
} from 'Components/AppCues/AppCuesLaunch';
import WeTeamIcon from 'Components/Icons/WeTeamIcon';
import { QuestionIcon } from 'Components/Icons/WeTeamIcon';
import DisableInBackupMode from 'Components/BackupMode/DisableInBackupMode';
import { showSuggestionsModal } from 'store/Modals/actions';

function HelpDropdown({
  locale,
  onTourSelected,
  onShowSuggestionsModal,
  intl,
}) {
  return (
    <DisableInBackupMode>
      <Dropdown
        icon={null}
        trigger={
          <Popup
            position="bottom right"
            inverted
            trigger={<WeTeamIcon Icon={QuestionIcon} />}
            content={<FormattedMessage id="HelpDropdown.Tooltip" />}
            size="mini"
          />
        }
        pointing={'top right'}
      >
        <Dropdown.Menu>
          <ShowIfNotAppStore
            all={
              <a
                className="item"
                href={getSupportEmailMailToLink()}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FormattedMessage id="HelpDropdown.EmailUs" />
              </a>
            }
          />
          <Dropdown.Item onClick={onShowSuggestionsModal}>
            <FormattedMessage id="HelpDropdown.Suggestions" />
          </Dropdown.Item>
          {onTourSelected && (
            <Dropdown
              text={intl.formatMessage({ id: 'HelpDropdown.Tour' })}
              pointing="right"
              className="link item"
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    onTourSelected(showIntroFlow);
                  }}
                >
                  <FormattedMessage id="HelpDropdown.Intro" />
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    onTourSelected(showChatFlow);
                  }}
                >
                  <FormattedMessage id="HelpDropdown.Chat" />
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    onTourSelected(showMeetFlow);
                  }}
                >
                  <FormattedMessage id="HelpDropdown.Meet" />
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    onTourSelected(showFilesFlow);
                  }}
                >
                  <FormattedMessage id="HelpDropdown.Files" />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          <a
            className="item"
            href={getPrivacyPolicyUrl(locale)}
            rel="noopener noreferrer"
            target="_blank"
          >
            <FormattedMessage id="HelpDropdown.PrivacyPolicy" />
          </a>
        </Dropdown.Menu>
      </Dropdown>
    </DisableInBackupMode>
  );
}

export default connect(
  state => ({
    locale: getLocale(state),
    intl: intlShape.isRequired,
  }),
  {
    onShowSuggestionsModal: showSuggestionsModal,
  }
)(injectIntl(HelpDropdown));
