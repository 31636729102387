import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SplitPane from 'react-split-pane';

import SidebarWrapper from './SidebarWrapper';
import withCanResizePanels from 'Components/Utilities/withCanResizePanels';

const SIDEBAR_WIDTH = 280;
const SIDEBAR_WIDTH_NON_RESIZABLE = 220;

const style = {
  position: 'relative',
  height: 'inherit',
};

class SidebarLayout extends Component {
  state = {
    leftPanelSize: this.props.sidebarWidth,
  };

  onLeftPanelSizeChange = leftPanelSize => {
    this.setState({
      leftPanelSize,
    });
  };

  render() {
    const { sidebar, header, children, canResize } = this.props;

    const canResizeLeftPanel = canResize; // left panel only resizable on desktop
    const localLeftPanelSize = canResize
      ? this.state.leftPanelSize || SIDEBAR_WIDTH
      : SIDEBAR_WIDTH_NON_RESIZABLE;

    return (
      <SplitPane
        className=""
        allowResize={canResizeLeftPanel}
        split="vertical"
        minSize={140}
        maxSize={320}
        size={localLeftPanelSize}
        onChange={this.onLeftPanelSizeChange}
        style={style}
      >
        <SidebarWrapper>{sidebar}</SidebarWrapper>
        <div className="flex column flex-auto">
          <div>{header}</div>
          {children}
        </div>
      </SplitPane>
    );
  }
}
SidebarLayout.propTypes = {
  sidebar: PropTypes.node.isRequired,
  header: PropTypes.node,
  children: PropTypes.node.isRequired,
  canResize: PropTypes.bool.isRequired,
  sidebarWidth: PropTypes.number.isRequired,
};

SidebarLayout.defaultProps = {
  sidebarWidth: SIDEBAR_WIDTH,
};

export default withCanResizePanels(SidebarLayout);
