import {
  createMeetingUser,
  createReference,
  referenceType,
} from 'DataLayer/Meeting/post';
import { put } from 'DataLayer/Meeting/Users/put';
import { activeMeetingUpdated } from 'store/Meeting';
import {
  getChannelIdByRoomId,
  getIsChannelMeetingByRoomId,
  getMeetingByRoomId,
} from 'store/Meeting/selectors';
import { makeGetChatCollaborator } from 'store/Spaces/Collaborators/selectors';

export const updateParticipants =
  (meetingId, chatsToInvite, participantIdsToRemove) =>
  async (dispatch, getState) => {
    try {
      const state = getState();

      const meeting = getMeetingByRoomId(state, meetingId);
      const isChannelMeeting = getIsChannelMeetingByRoomId(state, meetingId);
      const channelId = getChannelIdByRoomId(state, meetingId);

      const newParticipantsToInvite = chatsToInvite.map(chat => {
        const collaborator = makeGetChatCollaborator()(state, chat.Id);

        if (chat.external) {
          return createMeetingUser({
            email: chat.Email,
            participantType: 'EXTERNAL',
            invite: true,
          });
        }

        if (chat.fromApi) {
          return createMeetingUser({
            participantId: chat.UserId,
            firstName: chat.FirstName,
            lastName: chat.LastName,
            email: chat.Email,
            avatarUrl: chat.AvatarUrl,
            color: chat.Color,
            invite: true,
          });
        }

        return createMeetingUser({
          participantId: collaborator.UserId,
          firstName: collaborator.FirstName,
          lastName: collaborator.LastName,
          email: collaborator.Email,
          avatarUrl: collaborator.AvatarUrl,
          color: collaborator.Color,
          references: [
            createReference({
              type: referenceType.CHANNEL,
              id: isChannelMeeting ? channelId : chat.Id,
            }),
          ],
          invite: true,
        });
      });

      const participants = meeting.participants
        .concat(newParticipantsToInvite)
        .filter(p => {
          return !participantIdsToRemove.includes(p.participantId);
        });

      const newMeeting = await put(meetingId, participants);
      dispatch(activeMeetingUpdated(newMeeting));
    } catch (ex) {
      throw ex;
    }
  };
