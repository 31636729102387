import { FormattedMessage } from 'react-intl';

import React from 'react';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import { Message, Segment } from 'semantic-ui-react';

function ErrorBoundaryFallbackComponent() {
  return (
    <Segment basic>
      <Message
        negative
        onClick={() => {
          window.location.reload();
        }}
        className="cursor-pointer"
      >
        <FormattedMessage id="ErrorBoundary.it-seems-we-were-unable-to-load-this-content-click-here-to-try-again" />
      </Message>
    </Segment>
  );
}

class ErrorBoundary extends React.Component {
  state = { error: null };
  componentDidCatch(error, info) {
    this.setState({ error });
    Sentry.captureException(error, { info });
  }

  render() {
    const { showFallback, FallbackComponent } = this.props;
    if (this.state.error) {
      if (!showFallback) {
        return null;
      }
      return <FallbackComponent />;
    }
    return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  FallbackComponent: PropTypes.func.isRequired,
  showFallback: PropTypes.bool,
};

ErrorBoundary.defaultProps = {
  FallbackComponent: ErrorBoundaryFallbackComponent,
  showFallback: true,
};

export default ErrorBoundary;
