import axios from 'axios';
import {
  MEETINGS_INSTANT,
  MEETINGS_SCHEDULED,
  getUnverifiedUserRequestAllowedConfig,
  MEETINGS,
  getStartScheduledMeetingUrl,
  getInitScheduledMeetingUrl,
} from 'DataLayer/endpoints';
import { getOtixoBackendApiUrl } from '../../config/config';

export const createReference = ({ type, id, name = '' }) => ({
  referenceType: type,
  referenceName: name,
  referenceId: id,
});

export const referenceType = {
  WORKSPACE: 'WORKSPACE',
  CHANNEL: 'CHANNEL',
};

export const participantType = {
  INTERNAL: 'INTERNAL',
  EXTERNAL: 'EXTERNAL',
};

export const createMeetingUser = ({
  participantId,
  firstName,
  lastName,
  email,
  avatarUrl,
  color,
  channelId,
  invite,
  participantType,
  moderator,
  references = [],
}) => ({
  participantId,
  firstName,
  lastName,
  email,
  avatarUrl,
  color,
  channelId,
  invite,
  participantType,
  moderator,
  references,
});

export function post({ references, participants, autoApprove }) {
  const config = getUnverifiedUserRequestAllowedConfig();
  config.baseURL = getOtixoBackendApiUrl();

  return axios
    .post(
      MEETINGS,
      {
        references,
        participants,
        autoApprove,
      },
      config
    )
    .then(response => response.data);
}

export function postInstant(roomName, displayName) {
  const config = getUnverifiedUserRequestAllowedConfig();
  config.baseURL = getOtixoBackendApiUrl();

  return axios
    .post(MEETINGS_INSTANT, { roomName, displayName }, config)
    .then(response => response.data);
}

export function postScheduled(
  roomName,
  host,
  hostName,
  guests,
  language,
  template,
  meetingStartsAt,
  meetingEndsAt,
  timezone,
  recapchaToken
) {
  const config = getUnverifiedUserRequestAllowedConfig();
  config.baseURL = getOtixoBackendApiUrl();

  return axios
    .post(
      MEETINGS_SCHEDULED,
      {
        roomName,
        host,
        hostName,
        guests,
        language,
        template,
        meetingStartsAt,
        meetingEndsAt,
        timezone,
        recapchaToken,
      },
      config
    )
    .then(response => response.data);
}

export function startScheduled(meetingId, token) {
  const config = getUnverifiedUserRequestAllowedConfig();
  config.baseURL = getOtixoBackendApiUrl();

  return axios
    .post(getStartScheduledMeetingUrl(meetingId, token), {}, config)
    .then(response => response.data);
}

export function initScheduled(meetingId, token) {
  const config = getUnverifiedUserRequestAllowedConfig();
  config.baseURL = getOtixoBackendApiUrl();

  return axios
    .post(getInitScheduledMeetingUrl(meetingId, token), {}, config)
    .then(response => response.data);
}
