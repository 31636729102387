import { getOtixoBackendApiUrl } from 'config/config';
import { getPublicMeetingsUrl } from 'DataLayer/endpoints';
import { rest } from 'msw';

export const handlers = [
  // rest.put(
  //   `https://dev.we.team/otixo-backend/v2/meetings/39e8dd52-7fb1-4e14-8a7b-b2231d33455a/participants`,
  //   (req, res, ctx) => {
  //     return res(ctx.delay(600), ctx.status(200), ctx.json({}));
  //   }
  // ),
  rest.post(
    `https://dev.we.team/otixo-backend/v2/meetings`,
    (req, res, ctx) => {
      return res(
        ctx.delay(300),
        ctx.status(200),
        ctx.json({
          roomId: '39e8dd52-7fb1-4e14-8a7b-b2231d33455a',
          meetingServerUrl: 'https://meet-test.we.team',
          meetingStartedAt: 1738837369008,
          references: [
            {
              referenceId: 'a69aa0a7-f96b-4ed1-aee4-7b389ef6e44a',
              referenceName: 'Tag test',
              referenceType: 'WORKSPACE',
            },
            {
              referenceId: '886e5257-1c87-4b9a-9e5b-0f0e6660ff09',
              referenceName: 'Generalx',
              referenceType: 'CHANNEL',
            },
          ],
          participants: [
            {
              participantId: 'ex1', // external user id
              participantType: 'EXTERNAL', // EXTERNAL
              email: 'external1@otixo.com',
              invite: true,
              joined: true,
              moderator: false,
            },
            {
              participantId: 'ex2', // external user id
              participantType: 'EXTERNAL', // EXTERNAL
              email: 'external2@otixo.com',
              invite: true,
              joined: false,
              moderator: false,
            },
            {
              participantId: '8a8181108da6f423018da79aa2550000',
              avatarUrl: null,
              participantType: 'INTERNAL',
              references: [
                {
                  referenceId: '886e5257-1c87-4b9a-9e5b-0f0e6660ff09',
                  referenceName: 'Generalx',
                  referenceType: 'CHANNEL',
                },
              ],
              color: '#b2225d',
              email: 'jamie+feb13@otixo.com',
              firstName: 'jam',
              lastName: 'feb13',
              invite: false,
              joined: true,
              moderator: true,
            },
            {
              participantId: '8a8181148df972b4018df98456cf0000',
              avatarUrl:
                'https://dev.we.team/avatars/Avatar_8a8181148df972b4018df98456cf0000_1736937327947.jpeg',
              participantType: 'INTERNAL',
              references: [
                {
                  referenceId: '886e5257-1c87-4b9a-9e5b-0f0e6660ff09',
                  referenceName: 'Generalx',
                  referenceType: 'CHANNEL',
                },
              ],
              color: '#ff2f7d',
              email: 'jamie+mar1@otixo.com',
              firstName: 'jamie',
              lastName: 'mar1',
              invite: true,
              joined: true,
              moderator: false,
            },
          ],
        })
      );
    }
  ),
  rest.get(`https://dev.we.team/otixo-backend/v2/meetings`, (req, res, ctx) => {
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        activeMeetings: [
          {
            roomId: '39e8dd52-7fb1-4e14-8a7b-b2231d33455a',
            meetingServerUrl: 'https://meet-test.we.team',
            meetingStartedAt: 1738837369008,
            //channelName: 'Generalx',
            //workspaceId: 'a69aa0a7-f96b-4ed1-aee4-7b389ef6e44a',
            //workspaceName: 'Tag test',
            //externalUsers: ['jamie@google.co.de', 'jcarr@otixo.co'],
            //participantUserIds: ['8a8181108da6f423018da79aa2550000'],
            references: [
              {
                referenceId: 'a69aa0a7-f96b-4ed1-aee4-7b389ef6e44a',
                referenceName: 'Tag test',
                referenceType: 'WORKSPACE',
              },
              {
                referenceId: '886e5257-1c87-4b9a-9e5b-0f0e6660ff09',
                referenceName: 'Generalx',
                referenceType: 'CHANNEL',
              },
            ],
            participants: [
              {
                participantId: 'ex1', // external user id
                participantType: 'EXTERNAL', // EXTERNAL
                email: 'external1@otixo.com',
                invite: true,
                joined: false,
                moderator: false,
              },
              {
                participantId: 'ex2', // external user id
                participantType: 'EXTERNAL', // EXTERNAL
                email: 'external2@otixo.com',
                invite: true,
                joined: false,
                moderator: false,
              },
              {
                participantId: '8a8181108da6f423018da79aa2550000',
                avatarUrl: null,
                participantType: 'INTERNAL',
                references: [
                  {
                    referenceId: '886e5257-1c87-4b9a-9e5b-0f0e6660ff09',
                    referenceName: 'Generalx',
                    referenceType: 'CHANNEL',
                  },
                ],
                color: '#b2225d',
                email: 'jamie+feb13@otixo.com',
                firstName: 'jam',
                lastName: 'feb13',
                invite: false,
                joined: true,
                moderator: true,
              },
              {
                participantId: '8a8181148df972b4018df98456cf0000',
                avatarUrl:
                  'https://dev.we.team/avatars/Avatar_8a8181148df972b4018df98456cf0000_1736937327947.jpeg',
                participantType: 'INTERNAL',
                references: [
                  {
                    referenceId: '886e5257-1c87-4b9a-9e5b-0f0e6660ff09',
                    referenceName: 'Generalx',
                    referenceType: 'CHANNEL',
                  },
                ],
                color: '#ff2f7d',
                email: 'jamie+mar1@otixo.com',
                firstName: 'jamie',
                lastName: 'mar1',
                invite: true,
                joined: true,
                moderator: false,
              },
            ],
          },
          {
            roomId: 'f7c2c23f-683f-41bf-b94b-ce3660d3743a',
            meetingServerUrl: 'https://meet-test.we.team',
            meetingStartedAt: 1738837369008,
            references: [
              {
                referenceId: 'dbbd3aa6-e661-4392-81c8-027bbafabaf6',
                referenceName: 'Paid',
                referenceType: 'WORKSPACE',
              },
              {
                referenceId: 'f7c2c23f-683f-41bf-b94b-ce3660d3743a',
                referenceName: 'Generaldw',
                referenceType: 'CHANNEL',
              },
            ],
            participants: [
              {
                participantId: 'ex1', // external user id
                participantType: 'EXTERNAL', // EXTERNAL
                email: 'external1@otixo.com',
                invite: true,
                joined: false,
                moderator: false,
              },
              {
                participantId: 'ex2', // external user id
                participantType: 'EXTERNAL', // EXTERNAL
                email: 'external2@otixo.com',
                invite: true,
                joined: false,
                moderator: false,
              },
              {
                participantId: '8a8181108da6f423018da79aa2550000',
                avatarUrl: null,
                participantType: 'INTERNAL',
                references: [
                  {
                    referenceId: 'f7c2c23f-683f-41bf-b94b-ce3660d3743a',
                    referenceName: 'Generalx',
                    referenceType: 'CHANNEL',
                  },
                ],
                color: '#b2225d',
                email: 'jamie+feb13@otixo.com',
                firstName: 'jam',
                lastName: 'feb13',
                invite: false,
                joined: true,
                moderator: true,
              },
              {
                participantId: '8a8181148df972b4018df98456cf0000',
                avatarUrl:
                  'https://dev.we.team/avatars/Avatar_8a8181148df972b4018df98456cf0000_1736937327947.jpeg',
                participantType: 'INTERNAL',
                references: [
                  {
                    referenceId: 'f7c2c23f-683f-41bf-b94b-ce3660d3743a',
                    referenceName: 'Generalx',
                    referenceType: 'CHANNEL',
                  },
                ],
                color: '#ff2f7d',
                email: 'jamie+mar1@otixo.com',
                firstName: 'jamie',
                lastName: 'mar1',
                invite: true,
                joined: true,
                moderator: false,
              },
            ],
          },
        ],
        meetingInvitations: [],
      })
    );
  }),
  rest.get(
    `${getOtixoBackendApiUrl()}${getPublicMeetingsUrl('*')}`,
    (req, res, ctx) => {
      return res(
        ctx.delay(300),
        ctx.status(200),
        ctx.json({
          roomId: '39e8dd52-7fb1-4e14-8a7b-b2231d33455a',
          meetingServerUrl: 'https://meet-test.we.team',
          meetingStartedAt: 1738837369008,
          references: [
            {
              referenceId: 'a69aa0a7-f96b-4ed1-aee4-7b389ef6e44a',
              referenceName: 'Tag test',
              referenceType: 'WORKSPACE',
            },
            {
              referenceId: '886e5257-1c87-4b9a-9e5b-0f0e6660ff09',
              referenceName: 'Generalx',
              referenceType: 'CHANNEL',
            },
          ],
          participants: [
            {
              participantId: '8a8181108da6f423018da79aa2550000',
              avatarUrl: null,
              participantType: 'INTERNAL',
              references: [
                {
                  referenceId: '886e5257-1c87-4b9a-9e5b-0f0e6660ff09',
                  referenceName: 'Generalx',
                  referenceType: 'CHANNEL',
                },
              ],
              color: '#b2225d',
              email: 'jamie+feb13@otixo.com',
              firstName: 'jam',
              lastName: 'feb13',
              invite: false,
              joined: true,
              moderator: true,
            },
            {
              participantId: '8a8181148df972b4018df98456cf0000',
              avatarUrl:
                'https://dev.we.team/avatars/Avatar_8a8181148df972b4018df98456cf0000_1736937327947.jpeg',
              participantType: 'INTERNAL',
              references: [
                {
                  referenceId: '886e5257-1c87-4b9a-9e5b-0f0e6660ff09',
                  referenceName: 'Generalx',
                  referenceType: 'CHANNEL',
                },
              ],
              color: '#ff2f7d',
              email: 'jamie+mar1@otixo.com',
              firstName: 'jamie',
              lastName: 'mar1',
              invite: true,
              joined: true,
              moderator: false,
            },
          ],
        })
      );
    }
  ),
];
