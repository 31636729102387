import { useState } from 'react';
import PropTypes from 'prop-types';

import ManageParticipantsIcon from 'Components/Icons/Meeting/ManageParticipantsIcon';
import ManageParticipantsModal from './ManageParticipantsModal';

function ManageParticipantsButton({ meeting, className }) {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      {modalVisible && (
        <ManageParticipantsModal
          meeting={meeting}
          onClose={() => {
            setModalVisible(false);
          }}
        />
      )}
      <span
        onClick={() => {
          setModalVisible(true);
        }}
        role="button"
        className={className}
      >
        <ManageParticipantsIcon
          onClick={() => {
            setModalVisible(true);
          }}
        />
      </span>
    </>
  );
}

ManageParticipantsButton.propTypes = {
  meeting: PropTypes.object.isRequired,
};

export default ManageParticipantsButton;
