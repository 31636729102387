import {
  MEETING_INVITATION_REJECTED,
  MEETING_JOINED,
  MEETING_LEFT,
  MEETING_ACTIVE_HEARTBEAT,
  MEETING_INVITATION_ACCEPTED,
} from 'store/Meeting';
import { getUserGuid } from 'store/User/selectors';
import {
  createMeetingInvitationRejectedMessage,
  createMeetingParticipantJoinedMessage,
  createMeetingParticipantLeftMessage,
  createActiveMeetingMessage,
  createMeetingInvitationAcceptedMessage,
} from 'DataLayer/WebSocket/MeetingEvents';
import { sendWebsocketMessage } from 'store/websocket-middleware';
import { getLocalPartipiantId } from 'store/Meeting/selectors';

export const meetingMiddleware = store => next => action => {
  switch (action.type) {
    case MEETING_INVITATION_ACCEPTED: {
      const state = store.getState();
      const currentUserGuid = getUserGuid(state);
      store.dispatch(
        sendWebsocketMessage(
          createMeetingInvitationAcceptedMessage(currentUserGuid, action.roomId)
        )
      );
      break;
    }
    case MEETING_INVITATION_REJECTED: {
      const state = store.getState();
      const currentUserGuid = getUserGuid(state);
      store.dispatch(
        sendWebsocketMessage(
          createMeetingInvitationRejectedMessage(currentUserGuid, action.roomId)
        )
      );
      break;
    }
    case MEETING_JOINED: {
      const state = store.getState();
      const currentUserGuid = getUserGuid(state);
      store.dispatch(
        sendWebsocketMessage(
          createMeetingParticipantJoinedMessage(
            currentUserGuid,
            action.roomId,
            action.localParticipantId
          )
        )
      );
      break;
    }
    case MEETING_LEFT: {
      const state = store.getState();
      const currentUserGuid = getUserGuid(state);
      const localParticipantId = getLocalPartipiantId(state);
      store.dispatch(
        sendWebsocketMessage(
          createMeetingParticipantLeftMessage(
            currentUserGuid,
            action.roomId,
            localParticipantId
          )
        )
      );
      break;
    }
    case MEETING_ACTIVE_HEARTBEAT: {
      const state = store.getState();
      const currentUserGuid = getUserGuid(state);
      const localParticipantId = getLocalPartipiantId(state);
      store.dispatch(
        sendWebsocketMessage(
          createActiveMeetingMessage(
            currentUserGuid,
            action.roomId,
            localParticipantId
          )
        )
      );
      break;
    }
    default:
  }

  return next(action);
};
