import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import de from 'react-intl/locale-data/de';
import fr from 'react-intl/locale-data/fr';

import { syncHistoryWithStore } from 'react-router-redux';
import { createHistory } from 'history';
import { useRouterHistory, hashHistory } from 'react-router';

import {
  getOtixoBasePath,
  isNodeEnvDevelopment,
  isDesktopApp,
  shouldUseAuthToken,
  isCypressTestRun,
  getOtixoEnvironment,
  setDomain,
} from './config/config';
import ErrorBoundary from 'Components/ErrorBoundaries/ErrorBoundary';

/// import registerServiceWorker from './registerServiceWorker';

/*
    CSS included here for WebPack
    https://github.com/facebookincubator/create-react-app/blob/master/packages/react-scripts/template/README.md#adding-a-stylesheet
  */

import './assets/css/index.scss';

import Root from './Root';
import createStore from './store/createStore';
import { getToken } from 'Helpers/BrowserApi/localStorage';
import { init as initSentry } from 'Helpers/sentry';
import { setLocale } from 'store/Locale/setLocale';
import { getLocale } from 'Helpers/BrowserApi/navigator';

import { LOAD_BACKUP } from 'pages';
import { setBackupMode } from 'store/Application';

import GenericError from 'GenericError';

/* eslint-disable react-hooks/rules-of-hooks */
const browserHistory = useRouterHistory(createHistory)({
  /* eslint-enable react-hooks/rules-of-hooks */
  basename: getOtixoBasePath(),
});

const history = isDesktopApp() ? hashHistory : browserHistory;

const { store, persistor } = createStore(history);

const historyWithStore = syncHistoryWithStore(history, store);

store.dispatch(setLocale(getLocale()));

if (shouldUseAuthToken()) {
  const token = getToken();
  if (token) {
    axios.defaults.headers.common.Authorization = token;
  }
}

addLocaleData([...en, ...de, ...fr]);

console.debug(process.env.REACT_APP_VERSION, getOtixoEnvironment());

initSentry();

const renderApp = () => {
  ReactDOM.render(
    <ErrorBoundary FallbackComponent={GenericError}>
      <Root store={store} persistor={persistor} history={historyWithStore} />
    </ErrorBoundary>,
    document.getElementById('root')
  );
};

const startMockServiceWorker = worker => {
  worker
    .start({
      onUnhandledRequest: 'bypass',
      serviceWorker: {
        url: '/app/mockServiceWorker.js',
      },
    })
    .then(() => {
      // https://github.com/mswjs/msw/issues/353
      renderApp();
    });
};

if (isCypressTestRun()) {
  /*
    If we are running tests inside the Cypress test runner,
    include a Service Worker that intercepts xhr calls and mocks
    the responses
  */
  const { worker } = require('./mocks/browser.cypress');
  startMockServiceWorker(worker);
} else if (isDesktopApp()) {
  window.ipcRenderer?.invoke('getIsRunningBackMode').then(isRunningBackMode => {
    if (isRunningBackMode) {
      store.dispatch(setBackupMode(true));
      history.push(LOAD_BACKUP);
      renderApp();
    } else {
      window.ipcRenderer?.invoke('getDomain').then(domain => {
        setDomain(domain);
        renderApp();
      });
    }
  });
} else if (isNodeEnvDevelopment()) {
  /*
    If we are running locally,
    include a Service Worker that intercepts xhr calls and mocks
    the responses. This is useful if the backend API is not yet 
    available, or we want to test our app with specific responses
    */
  const { worker } = require('./mocks/browser');
  startMockServiceWorker(worker);
  //renderApp();
} else {
  renderApp();
}

if (isDesktopApp()) {
  window.ipcRenderer?.on('log', (event, ...data) => {
    console.debug('Main Process:', ...data);
  });
  window.ipcRenderer?.on('error', (event, ...data) => {
    console.debug('Main Process (error):', ...data);
    Sentry.captureMessage({ event, ...data });
  });
  window.ipcRenderer?.send('ipcReady');
}
