import { getLocale } from 'Helpers/BrowserApi/navigator';
import { Message, Segment } from 'semantic-ui-react';
import { supportedLocales } from 'translations/getMessageByLocale';

/**
 * React Intl is not available this high up the tree
 * Intead we need to hard code the error strings instead of using <FormattedMessage />
 * */
function GenericError() {
  return (
    <Segment basic>
      <Message
        negative
        onClick={() => {
          window.location.reload();
        }}
        className="cursor-pointer"
      >
        {getLocale() === supportedLocales.de
          ? 'Der Inhalt konnte nicht geladen werden. Klicke hier, um es erneut zu versuchen'
          : 'It seems we were unable to load this content. Click here to try again'}
      </Message>
    </Segment>
  );
}

export default GenericError;
