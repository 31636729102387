import ReconnectingWebSocket from 'reconnecting-websocket';

const PING_TIMEOUT = 4000; // time we wait for a pong
const PING_INTERVAL = 15000; // time between pings

export const isPong = message => {
  try {
    const parsedMessage = JSON.parse(message);
    return parsedMessage.action === 'pong';
  } catch {
    return false;
  }
};

const debug = message => {
  try {
    //Sentry.captureMessage(message, 'debug');
  } catch (e) {}
};

export const createWebSocket = url => {
  const websocket = new ReconnectingWebSocket(url, [], {
    debug: true,
    minReconnectionDelay: 1000,
  });
  let reconnectTimeout = 0;
  let pingTimeout = 0;

  const sendPing = () => {
    if (!websocket) return;
    if (websocket.readyState !== WebSocket.OPEN) {
      return;
    }
    websocket.send(JSON.stringify({ action: 'ping' }));
    // This timeout is cleared when a pong is received. If no pong is received, the websocket is reconnected
    reconnectTimeout = setTimeout(() => {
      debug(
        `websocket: START pong not received, reconnecting. websocket.readyState: ${websocket.readyState}`
      );
      websocket.reconnect();
      debug(
        `websocket: END pong not received, reconnecting. websocket.readyState: ${websocket.readyState}`
      );
    }, PING_TIMEOUT);
  };
  websocket.addEventListener('open', event => {
    debug('websocket: open');
    sendPing();
  });
  websocket.addEventListener('close', event => {
    debug('websocket: close');
    clearTimeout(reconnectTimeout);
    clearTimeout(pingTimeout);
  });
  websocket.addEventListener('message', event => {
    const message = event.data;
    if (isPong(message)) {
      // when the server responds to a ping, we clear the timeout used to close the websocket
      clearTimeout(reconnectTimeout);
      pingTimeout = setTimeout(() => {
        sendPing();
      }, PING_INTERVAL);
    }
  });
  return websocket;
};
