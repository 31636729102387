import { referenceType } from 'DataLayer/Meeting/post';
import { MEETING_STATUS } from '.';
import { createSelector } from 'reselect';
import {
  getMaximumMeetingParticipants,
  getSpaces,
} from 'store/Spaces/selectors';
import { getUserGuid } from 'store/User/selectors';

export const MEETING_BEACON_TYPES = {
  ACTIVE: 'ACTIVE',
  JOINED: 'JOINED',
};

export const getIsMeetingStarting = state =>
  state.meeting.status === MEETING_STATUS.STARTING;

export const getMeetingStatus = state => state.meeting.status;

export const getIsMeetingJoined = state =>
  state.meeting.status === MEETING_STATUS.JOINED;

export const getIsMeetingHangUpRequested = state =>
  state.meeting.status === MEETING_STATUS.HANG_UP_REQUESTED;

export const getIsMeetingActive = state =>
  state.meeting.status === MEETING_STATUS.JOINED ||
  state.meeting.status === MEETING_STATUS.STARTING ||
  state.meeting.status === MEETING_STATUS.HANG_UP_REQUESTED;

export const getActiveMeetingRoomId = state => state.meeting.roomId;

export const getLocalPartipiantId = state => state.meeting.localParticipantId;

export const isMeetingJoined = (state, roomId) =>
  state.meeting.status === MEETING_STATUS.JOINED &&
  state.meeting.roomId === roomId;

export const getActiveMeetingsObject = state => state.meeting.activeMeetings;

export const getMeetingByRoomId = (state, roomId) =>
  state.meeting.activeMeetings[roomId];

export const getJoinedMeetingRoomId = state => {
  if (state.meeting.status === MEETING_STATUS.JOINED) {
    return state.meeting.roomId;
  }
  return undefined;
};

export const isMeetingAudioMuted = state => state.meeting.isAudioMuted;

export const isMeetingVideoMuted = state => state.meeting.isVideoMuted;

export const isMeetingScreenShared = state => state.meeting.isScreenShared;

export const getMeetingServerUrl = state => state.meeting.meetingServerUrl;

export const makeGetMeetingBeaconByChannelId = () =>
  createSelector(
    [
      getActiveMeetings,
      getJoinedMeetingRoomId,
      (_, channelId) => channelId,
      getUserGuid,
    ],
    (activeMeetings, joinedMeetingRoomId, channelId, currentUserGuid) => {
      /*
        Check if the channelId appears in the any active meeting
      */
      const channelHasMeeting = activeMeetings.some(meeting =>
        meeting.references?.some(r => r.referenceId === channelId)
      );

      if (!channelHasMeeting) {
        return null;
      }

      /**
       * Check if the participant has joined the meeting
       * channel meetings should only show green if the current user has joined
       */
      const participantHasJoined = activeMeetings
        .filter(meeting => !isChannelMeeting(meeting))
        .some(meeting => {
          /**
           * get corresponding userId of this channel and see if the userId is in the participant list i.e. they have joined the meeting
           * */
          const participantId = meeting.participants?.find(p =>
            p.references?.some(r => r.referenceId === channelId)
          )?.participantId;

          /**
           * if we found the current user, this is the channel they share with the caller,
           * so we should instead check if the caller is in the participant list
           */
          if (participantId === currentUserGuid) {
            return meeting.joinedParticipants
              ?.map(p => p.participantId)
              .includes(meeting.caller.participantId);
          }

          return meeting.joinedParticipants
            ?.map(p => p.participantId)
            .includes(participantId);
        });

      if (participantHasJoined) {
        return MEETING_BEACON_TYPES.JOINED;
      }

      /*
        Check if the channelId appears in the running meeting
      */
      const joinedMeetingRoom = activeMeetings.find(
        m => m.roomId === joinedMeetingRoomId
      );
      if (isChannelMeeting(joinedMeetingRoom)) {
        if (
          joinedMeetingRoom?.participants?.some(p =>
            p.references?.some(r => r.referenceId === channelId)
          )
        ) {
          return MEETING_BEACON_TYPES.JOINED;
        }
      }

      return MEETING_BEACON_TYPES.ACTIVE;
    }
  );

export const makeGetMeetingBeaconByWorkspaceId = () =>
  createSelector(
    [
      getActiveMeetingsObject,
      getJoinedMeetingRoomId,
      (_, workspaceId) => workspaceId,
    ],
    (activeMeetings, joinedMeetingRoomId, workspaceId) => {
      if (
        activeMeetings[joinedMeetingRoomId]?.references?.some(
          r => r.referenceId === workspaceId
        )
      ) {
        return MEETING_BEACON_TYPES.JOINED;
      }
      if (
        Object.values(activeMeetings).some(meeting =>
          meeting.references?.some(r => r.referenceId === workspaceId)
        )
      ) {
        return MEETING_BEACON_TYPES.ACTIVE;
      }
      return null;
    }
  );

export const getActiveMeetings = createSelector(
  [getActiveMeetingsObject],
  activeMeetings =>
    Object.values(activeMeetings).map(meeting => ({
      ...meeting,
      caller: meeting.participants?.find(p => p.moderator),
      joinedParticipants: meeting.participants?.filter(p => p.joined),
      workspaceId: meeting.references.find(
        r => r.referenceType === referenceType.WORKSPACE
      )?.referenceId,
      workspaceName: meeting.references.find(
        r => r.referenceType === referenceType.WORKSPACE
      )?.referenceName,
      channelName: meeting.references.find(
        r => r.referenceType === referenceType.CHANNEL
      )?.referenceName,
    }))
);

export const getNumberOfActiveMeetings = createSelector(
  [getActiveMeetings],
  meetings => meetings.length
);

export const getActiveMeetingInvitations = createSelector(
  [getActiveMeetings],
  activeMeetings => {
    return activeMeetings.filter(
      activeMeeting => activeMeeting.state === MEETING_STATUS.INVITED
    );
  }
);

export const makeIsCurrentUserAParticipantInMeeting = () =>
  createSelector(
    [getActiveMeetings, getUserGuid, (_, meetingId) => meetingId],
    (activeMeetings, userGuid, meetingId) => {
      const meeting = activeMeetings.find(
        activeMeeting => activeMeeting.roomId === meetingId
      );
      return meeting?.joinedParticipants
        .map(p => p.participantId)
        .includes(userGuid);
    }
  );

export const getMeeting = createSelector(
  [getActiveMeetings, (_, roomId) => roomId],
  (activeMeetings, roomId) => {
    return activeMeetings.find(
      activeMeeting => activeMeeting.roomId === roomId
    );
  }
);

const getNumberOfPaticipantsByRoomId = createSelector(
  [getActiveMeetings, (_, roomId) => roomId],
  (activeMeetings, roomId) => {
    const meeting = activeMeetings.find(
      activeMeeting => activeMeeting.roomId === roomId
    );
    return meeting?.joinedParticipants?.map(p => p.participantId).length ?? 0;
  }
);

export const isMeetingRestrictedByRoomId = createSelector(
  [getNumberOfPaticipantsByRoomId, getMaximumMeetingParticipants],
  (numberOfMeetingParticipants, maximumMeetingParticipants) => {
    //TODO: Move maximumMeetingParticipants value to Workspace
    return numberOfMeetingParticipants >= maximumMeetingParticipants;
  }
);

export const isRemoteDesktopEnabledByRoomId = createSelector(
  [getMeetingByRoomId, getSpaces],
  (meeting, channels) => {
    try {
      const channelId = meeting.references.find(
        reference => reference.referenceType === referenceType.CHANNEL
      )?.referenceId;
      const channel = channels.find(channel => channel.Id === channelId);
      return channel?.ACL?.ShareDesktop;
    } catch (ex) {
      return false;
    }
  }
);

export const isInviteEnabledByRoomId = createSelector(
  [getMeetingByRoomId, getSpaces],
  (meeting, channels) => {
    try {
      const channelId = meeting.references.find(
        r => r.referenceType === referenceType.CHANNEL
      )?.referenceId;
      const channel = channels.find(channel => channel.Id === channelId);
      return channel?.ACL?.JitsiMeetingInvite;
    } catch (ex) {
      return false;
    }
  }
);

const isChannelMeeting = meeting => {
  try {
    /* 
    A member meeting will have multiple channelIds.
    Get unique channelIds. 
    */
    const channels = new Set(
      meeting.participants.map(
        p =>
          p.references.find(r => r.referenceType === referenceType.CHANNEL)
            ?.referenceId
      )
    );
    return channels.size === 1;
  } catch (ex) {
    return false;
  }
};

export const getIsChannelMeetingByRoomId = createSelector(
  [getMeetingByRoomId],
  isChannelMeeting
);

export const getChannelIdByRoomId = createSelector(
  [getMeetingByRoomId],
  meeting => {
    try {
      return meeting.references.find(
        r => r.referenceType === referenceType.CHANNEL
      )?.referenceId;
    } catch (e) {
      return null;
    }
  }
);
