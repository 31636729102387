import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Popup } from 'semantic-ui-react';

import './RightSidebarButton.scss';
import classNames from 'classnames/bind';

class RightSidebarButton extends PureComponent {
  render() {
    const { children, onClick, openTooltip, closeTooltip, open, className } =
      this.props;
    return (
      <Popup
        inverted
        position="left center"
        trigger={
          <div
            className={classNames(className, 'right-sidebar-button')}
            onClick={onClick}
            role="button"
          >
            {children}
          </div>
        }
        content={<FormattedMessage id={open ? openTooltip : closeTooltip} />}
        size="mini"
      />
    );
  }
}

RightSidebarButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  openTooltip: PropTypes.string.isRequired,
  closeTooltip: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

export default RightSidebarButton;
