import React from 'react';
import PropTypes from 'prop-types';

function RightSidebarPanelFilter({ children, label }) {
  return (
    <div className="mb-4">
      {label && <div className="mb-3">{label}</div>}
      {children}
    </div>
  );
}
RightSidebarPanelFilter.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.node,
};

export default RightSidebarPanelFilter;
