import PropTypes from 'prop-types';
import React from 'react';
import ParticipantAvatar from 'Components/Meeting/ParticipantAvatar';
import { FormattedHTMLMessage } from 'react-intl';

import styles from './MeetingUserAvatars.module.css';

function MeetingUserAvatars({ meeting }) {
  return (
    <div className={styles.onTheCall}>
      <span className="flex-no-shrink">
        <FormattedHTMLMessage id="MeetingInvitation.Invited" />
      </span>
      <div className={styles.participants}>
        {meeting.participants
          ?.filter(p => p.invite)
          .map(p => (
            <ParticipantAvatar key={p.participantId} collaborator={p} />
          ))}
      </div>
    </div>
  );
}

MeetingUserAvatars.propTypes = {
  meeting: PropTypes.shape({
    participants: PropTypes.array.isRequired,
  }).isRequired,
};

export default MeetingUserAvatars;
