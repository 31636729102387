import React from 'react';
import PropTypes from 'prop-types';

import Beacon from './Beacon';
import { MEETING_BEACON_TYPES } from '../../store/Meeting/selectors';

const MeetingBeacon = ({ meetingBeaconType, width, height }) => {
  if (!meetingBeaconType) {
    return null;
  }

  return (
    <Beacon
      width={width}
      height={height}
      active={meetingBeaconType === MEETING_BEACON_TYPES.JOINED}
    />
  );
};

MeetingBeacon.propTypes = {
  meetingBeaconType: PropTypes.string,
};

export default MeetingBeacon;
