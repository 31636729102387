import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Segment, Message } from 'semantic-ui-react';

import MeetingsRestrictedMessage from 'Components/Meeting/MeetingsRestrictedMessage';
import MeetingToolbar from 'Components/Meeting/MeetingToolbar';
import ParticipantAvatars from 'Components/Meeting/ParticipantAvatars';
import CurrentMeetingWillEndMessage from 'Components/Meeting/CurrentMeetingWillEndMessage';
import MeetingUserAvatars from 'Components/Meeting/MeetingUserAvatars';

import styles from './Meeting.module.scss';

export function Meeting({ activeMeeting }) {
  return (
    <>
      <p>
        <FormattedMessage id="Meeting.Started" />
      </p>
      <div className={styles.meetingInvitation}>
        <Segment padded>
          <div className="mb-3">
            <MeetingUserAvatars meeting={activeMeeting} />
          </div>
          <div>
            <ParticipantAvatars meeting={activeMeeting} />
          </div>
          <CurrentMeetingWillEndMessage roomId={activeMeeting.roomId} />
          <MeetingsRestrictedMessage
            As={Message}
            meeting={activeMeeting}
            showUpgradeButton
          />
        </Segment>
        <MeetingToolbar meeting={activeMeeting} />
      </div>
    </>
  );
}

Meeting.propTypes = {
  activeMeeting: PropTypes.shape({
    roomId: PropTypes.string.isRequired,
    participants: PropTypes.array.isRequired,
    caller: PropTypes.object.isRequired,
    channelName: PropTypes.string.isRequired,
    workspaceName: PropTypes.string.isRequired,
  }).isRequired,
  isMeetingJoined: PropTypes.bool,
};

export default Meeting;
