import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import { Button, TextArea, Modal, Header } from 'semantic-ui-react';

import styles from './EnterMultipleEmailModal.module.css';

export const getEmailAddressesFromString = emails =>
  emails
    .replace(/(?:\r\n|\r|\n)/g, ',') // replace new line with comma
    .replace(/ /g, ',')
    .split(/[;,]+/) // split at comma or colon
    .map(email => email.trim()) // remove whitespace
    .filter(email => email.length); // remove empty strings

export class EnterMultipleEmailModal extends React.Component {
  state = {
    multiEmail: '',
  };

  setMultiEmail = () => {
    const emails = getEmailAddressesFromString(this.state.multiEmail);
    this.props.onSubmit(emails);
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { intl } = this.props;
    return (
      <Modal size="small" open onClose={this.props.onClose}>
        <div className={styles.closeModal}>
          <button className="close_modal_button" onClick={this.props.onClose} />
        </div>
        <Header>
          <FormattedMessage id="EnterMultipleEmailModal.Title" />
        </Header>
        <Modal.Content>
          <TextArea
            data-testid="multiEmail"
            autoFocus
            name="multiEmail"
            id="multiEmail"
            value={this.state.multiEmail}
            onChange={this.handleChange}
            placeholder={intl.formatMessage({
              id: 'EnterMultipleEmailModal.example-email',
            })}
          />
          <p>
            <FormattedMessage id="EnterMultipleEmailModal.HelpText" />
          </p>
        </Modal.Content>
        <Modal.Actions>
          <div className="modal-buttons">
            <Button basic onClick={this.props.onClose}>
              <FormattedMessage id="Cancel" />
            </Button>
            <Button primary onClick={this.setMultiEmail} data-testid="submit">
              <FormattedMessage id="EnterMultipleEmailModal.Add" />
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}

EnterMultipleEmailModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(EnterMultipleEmailModal);
