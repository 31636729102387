import PropTypes from 'prop-types';

import { useMessageSelectionContext } from 'Components/Chat/SpaceChat/MessageSelection/MessageSelectionContext';
import WeTeamIcon, {
  CheckSquareIcon,
  SquareIcon,
} from 'Components/Icons/WeTeamIcon';

const MessageSelectionSelectOption = ({
  messageId,
  canDelete,
  canShare,
  canLaunch,
  resourceId,
  alwaysChecked = false,
  ...props
}) => {
  const { messageSelected, isMessageSelected } = useMessageSelectionContext();

  return (
    <WeTeamIcon
      {...props}
      Icon={
        isMessageSelected(messageId) || alwaysChecked
          ? CheckSquareIcon
          : SquareIcon
      }
      onClick={() => {
        messageSelected({
          id: messageId,
          canDelete,
          canShare,
          canLaunch,
          resourceId,
        });
      }}
    />
  );
};

MessageSelectionSelectOption.propTypes = {
  messageId: PropTypes.string.isRequired,
  canDelete: PropTypes.bool.isRequired,
  canShare: PropTypes.bool.isRequired,
  canLaunch: PropTypes.bool.isRequired,
  alwaysChecked: PropTypes.bool,
  resourceId: PropTypes.string,
};

export default MessageSelectionSelectOption;
