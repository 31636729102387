//import * as Sentry from '@sentry/react';
import get from 'lodash/get';
import { removeResourceSuccess } from 'store/Resources/removeResource';
import { changeOnlineStaus, updateQuota } from 'store/Repositories/actions';
import {
  addTask,
  TRANSFER_STATUS,
  TRANSFER_TYPE,
} from 'store/Transfers/actions';
import { loadRepositories } from 'store/Repositories/loadRepositories';
import {
  updateCollaboratorPresence,
  updateLastUpdatedTimestamp,
  archiveSpace,
  removeSpace,
  changeSpaceAvatar,
  lockSpace,
} from 'store/Spaces/actions';
import { loadLinkedItem } from 'store/LinkedItems/loadLinkedItem';
import { loadResource } from 'store/Resources/loadResource';
import {
  addNotification,
  setStats,
  removeNotificationById,
  updateNotificationMessage,
  notificationStarAdded,
  readStateChanged,
} from 'store/Notifications/actions';

import { createDesktopNotification } from 'Helpers/BrowserApi/desktopNotification';

import {
  setLinkedItemLocked,
  removeLinkedItems,
  linkedItemStarAdded,
} from 'store/LinkedItems/actions';
import loadStarred from 'store/Starred/loadStarred';
import { resourceStarAdded } from 'store/Entities/actions';
import { displaySuccessToast } from 'store/Toast/displayToast';
import { addComment, removeComment, editComment } from 'store/Comments/actions';
import { getUserId } from 'store/User/selectors';
import loadSpace from 'store/Spaces/loadSpace';
import { userAvatarChanged, bandwidthChanged } from 'store/User/actions';
import loadWorkspaces from 'store/Workspaces/loadWorkspaces';
import loadMembersByWorkspaceId from 'store/Workspaces/Members/loadMembersByWorkspaceId';
import { typingStarted, typingStopped } from 'store/IsTyping/actions';

import { reminderAdded, reminderRemoved } from 'store/Reminders';
import { isDesktopApp } from 'config/config';
import notificationType from 'Constants/notificationType';
import { isWindowFocussed } from 'store/Window';
import loadSpaces from 'store/Spaces/loadSpaces';
import loadRecentlyAddedWorkspaces from 'store/Workspaces/loadRecentlyAddedWorkspaces';
import { isPlainObject } from 'lodash';
import {
  activeMeetingEnded,
  activeMeetingUpdated,
  meetingInvitationReceived,
  hideMeetingInvitation,
} from 'store/Meeting';
import { push } from 'react-router-redux';
import { getSpaceLink } from 'pages';
import { getPathname } from 'store/Routing/selectors';
import { showTransfersProgressModal } from 'store/Modals/actions';
import {
  noteDataChanged,
  realTimeNoteDataChanged,
  tagDataChanged,
  websocketStatusChanged,
} from 'store/Connectivity';
import { createWebSocket, isPong } from 'store/createWebSocket';

const SEND_MESSAGE_INTERVAL = 1000; // time between re-attempting send message
const SEND_MESSAGE_MAX_ATTEMPS = 10;
const SEND_WEB_SOCKET_MESSAGE = 'SEND_WEB_SOCKET_MESSAGE';

const CONNECT_WEB_SOCKET = 'CONNECT_WEB_SOCKET';

export const connectWebSocket = url => ({
  type: CONNECT_WEB_SOCKET,
  url,
});

export const sendWebsocketMessage = payload => ({
  type: SEND_WEB_SOCKET_MESSAGE,
  payload,
});

export const websocketMiddleware = store => next => {
  let websocket;
  let canSendMessages;

  const setupWebsocket = url => {
    if (websocket) {
      return;
    }
    websocket = createWebSocket(url);

    websocket.addEventListener('open', event => {
      next(websocketStatusChanged(true));
    });
    websocket.addEventListener('close', event => {
      canSendMessages = false;
      next(websocketStatusChanged(false));
    });
    websocket.addEventListener('message', event => {
      const message = event.data;
      if (isPong(message)) {
        canSendMessages = true;
      } else {
        handleNotification(event.data);
      }
    });
  };

  const showDesktopNotification = async notification => {
    if (notification.Title && notification.Message) {
      if (isDesktopApp()) {
        window.ipcRenderer?.send('desktop-notification', {
          title: notification.Title,
          message: notification.Message,
        });
      } else {
        const desktopNotification = await createDesktopNotification({
          title: notification.Title,
          message: notification.Message,
          data: {
            channelId: get(notification, 'Workspace.Id'),
          },
        });

        desktopNotification.onclick = e => {
          window.focus();
          desktopNotification.close();
          const channelId = get(e, 'target.data.channelId');
          if (channelId) {
            store.dispatch(push(getSpaceLink(channelId)));
          }
        };
      }
    }
  };

  const shouldShowDesktopNotification = notification => {
    if (notification.Type === notificationType.ReminderFired) {
      return true;
    }
    const state = store.getState();
    const isFocussed = isWindowFocussed(state);
    const currentUserId = getUserId(state);
    if (getPathname(state).includes(notification.Workspace?.Id) && isFocussed) {
      // no need to display a notification if we are in Otixo and viewing the chat
      return false;
    }

    return notification.CreatedBy.UserID !== currentUserId;
  };

  const addNotificationToStore = notification => {
    store.dispatch(addNotification(notification));
    if (shouldShowDesktopNotification(notification)) {
      showDesktopNotification(notification);
    }
  };

  const hasWorkSpaceId = notification => {
    return notification.Workspace && notification.Workspace.Id;
  };

  const reloadWorkSpace = workspaceId => {
    setTimeout(() => {
      //  also fetch list of channels
      // timeout required because if we request immediately, sometimes the new channels are not in the list
      store.dispatch(loadSpaces(workspaceId));
      store.dispatch(loadMembersByWorkspaceId(workspaceId));
    }, 5000);
    // also fetch the members for the specific work space, so the list is up to date.
  };
  const notificationHandlers = {
    [notificationType.OnlineStatusUpdated]: notification => {
      notification.Items.forEach(cloud => {
        const cloudId = cloud.cloudId;
        const onlineStatus = cloud.online;
        store.dispatch(changeOnlineStaus(cloudId, onlineStatus));
      });
    },
    [notificationType.ChannelNotificationCountUpdate]: notification => {
      store.dispatch(setStats(notification.stats));
    },
    [notificationType.QuotaUpdated]: notification => {
      notification.Items.forEach(cloud => {
        const cloudId = cloud.cloudId;
        const totalSpace = cloud.totalSpace;
        const usedSpace = cloud.usedSpace;
        store.dispatch(updateQuota(cloudId, totalSpace, usedSpace));
      });
    },
    [notificationType.ResourceDeleted]: notification => {
      store.dispatch(
        removeResourceSuccess(notification.Resource.ParentId, [
          notification.Resource.Id,
        ])
      );
    },
    [notificationType.ChannelDeleteResource]: notification => {
      store.dispatch(
        removeResourceSuccess(notification.Resource.ParentId, [
          notification.Resource.Id,
        ])
      );
    },
    [notificationType.RepositoryCreated]: () => {
      store.dispatch(loadRepositories());
    },
    [notificationType.TaskEvent]: notification => {
      store.dispatch(addTask(notification.Task));
      if (notification.Task.Type === TRANSFER_TYPE.TRANSFER_RESOURCES) {
        store.dispatch(showTransfersProgressModal());
      }
      if (notification.Task.Status === TRANSFER_STATUS.COMPLETED) {
        addNotificationToStore(notification);
      }
    },
    [notificationType.ResourceComment]: notification => {
      // don't include if it is my comment as this is alread in the store

      // TODO: CHange on server so the Id property is consistent
      notification.Comment.Id = notification.Comment.CommentId;
      const state = store.getState();
      const currentUserId = getUserId(state);
      addNotificationToStore(notification);
      if (
        notification.CreatedBy &&
        notification.CreatedBy.UserID === currentUserId
      ) {
        return;
      }
      if (notification.Workspace && notification.Resource) {
        store.dispatch(
          addComment(
            notification.Workspace.Id,
            notification.Resource.Id,
            notification.Comment,
            get(notification, 'CreatedBy.UserID') === currentUserId
          )
        );
      }
    },
    [notificationType.PresenceChangedEvent]: notification => {
      store.dispatch(
        updateCollaboratorPresence(
          notification.UserPresence.User.Email,
          `${notification.UserPresence.User.FirstName} ${notification.UserPresence.User.LastName}`,
          notification.UserPresence.Presence,
          notification.UserPresence.LastOnline
        )
      );
    },
    [notificationType.ChannelComment]: notification => {
      if (hasWorkSpaceId(notification)) {
        // this is causing problems by clearning temp messages that have not come back over the ws yet!
        // store.dispatch(clearTemporaryNotification(notification.Comment.CommentId));
        addNotificationToStore(notification);
      }
    },
    [notificationType.ReminderFired]: notification => {
      if (hasWorkSpaceId(notification)) {
        addNotificationToStore(notification);
      }
      store.dispatch(reminderAdded(notification.Reminder));
    },
    [notificationType.ReminderAdded]: notification => {
      store.dispatch(reminderAdded(notification.Reminder));
    },
    [notificationType.ReminderEdited]: notification => {
      store.dispatch(reminderAdded(notification.Reminder));
    },
    [notificationType.ReminderDeleted]: notification => {
      store.dispatch(reminderRemoved(notification.ReminderId));
    },
    [notificationType.DeleteNotification]: notification => {
      store.dispatch(removeNotificationById(notification.NotificationId));
    },
    [notificationType.EditNotification]: notification => {
      if (
        notification.Notification.Type === notificationType.ShareTaskEvent ||
        notification.Notification.Type === notificationType.ReminderFired
      ) {
        addNotificationToStore(notification.Notification);
      } else {
        store.dispatch(
          updateNotificationMessage(
            notification.Notification.Id,
            notification.Notification.Comment.Text
          )
        );
      }
    },
    [notificationType.StarredNotification]: notification => {
      store.dispatch(
        notificationStarAdded(notification.NotificationId, notification.Starred)
      );
      store.dispatch(loadStarred());
    },
    [notificationType.StarredResource]: resource => {
      store.dispatch(resourceStarAdded(resource.ResourceId, resource.Starred));
      store.dispatch(loadStarred());
    },
    [notificationType.StarredLinkedItem]: linkedItem => {
      store.dispatch(
        linkedItemStarAdded(linkedItem.ResourceId, linkedItem.Starred)
      );
      store.dispatch(loadStarred());
    },
    [notificationType.ResourceDeleteNotification]: notification => {
      store.dispatch(
        removeComment(
          notification.SpaceId,
          notification.ResourceId,
          notification.CommentId
        )
      );
    },
    [notificationType.ResourceEditNotification]: notification => {
      store.dispatch(editComment(notification.CommentId, notification.Message));
    },
    [notificationType.ChannelCollaboratorStatusChanged]: notification => {
      if (hasWorkSpaceId(notification)) {
        store.dispatch(loadSpace(notification.Workspace.Id));
        addNotificationToStore(notification);
      }
    },
    [notificationType.ChannelCollaboratorRemoved]: notification => {
      if (hasWorkSpaceId(notification)) {
        store.dispatch(loadSpace(notification.Workspace.Id));
        addNotificationToStore(notification);
      }
    },
    [notificationType.ChannelCollaboratorAdded]: notification => {
      if (hasWorkSpaceId(notification)) {
        store.dispatch(loadSpace(notification.Workspace.Id));
        addNotificationToStore(notification);
      }
    },
    [notificationType.GWorkspaceCollaboratorRejected]: notification => {
      if (hasWorkSpaceId(notification)) {
        addNotificationToStore(notification);
      }
    },
    [notificationType.GWorkspaceCollaboratorApproved]: notification => {
      if (hasWorkSpaceId(notification)) {
        addNotificationToStore(notification);
      }
    },
    [notificationType.GWorkspaceCollaboratorApprovalRequested]:
      notification => {
        if (hasWorkSpaceId(notification)) {
          store.dispatch(loadSpace(notification.Workspace.Id));
          addNotificationToStore(notification);
        }
      },
    [notificationType.RevokedChannelAccess]: notification => {
      if (hasWorkSpaceId(notification)) {
        store.dispatch(loadWorkspaces()).then(() => {
          store.dispatch(removeSpace(notification.Workspace.Id));
          store.dispatch(
            displaySuccessToast('OtixoWebSocket.GRevokedChannelAccess', {
              name: notification.Workspace.Name,
            })
          );
        });
      }
    },
    [notificationType.ChannelDeleted]: notification => {
      if (hasWorkSpaceId(notification)) {
        // check if it was deleted by someone else
        const state = store.getState();
        const currentUserId = getUserId(state);
        if (notification.CreatedBy.UserID !== currentUserId) {
          store.dispatch(loadWorkspaces()).then(() => {
            store.dispatch(removeSpace(notification.Workspace.Id));
            store.dispatch(
              displaySuccessToast('OtixoWebSocket.GChannelDeleted', {
                name: notification.Workspace.Name,
              })
            );
          });
        }
      }
    },
    [notificationType.ChannelCreated]: notification => {
      store.dispatch(loadSpace(notification.Workspace.Id));
    },
    [notificationType.ChannelResourceUnlinked]: notification => {
      if (hasWorkSpaceId(notification)) {
        store.dispatch(
          removeLinkedItems(
            [notification.Resource.Id],
            notification.Workspace.Id
          )
        );
        addNotificationToStore(notification);
      }
    },
    [notificationType.ChannelResourceLinked]: notification => {
      if (hasWorkSpaceId(notification)) {
        store.dispatch(
          loadLinkedItem(notification.Workspace.Id, notification.LinkId)
        );
        addNotificationToStore(notification);
      }
    },
    [notificationType.ChannelNewResource]: notification => {
      if (hasWorkSpaceId(notification)) {
        store.dispatch(loadResource(notification.Resource.Id, true));
        addNotificationToStore(notification);
      }
    },
    [notificationType.ResourceNew]: notification => {
      store.dispatch(loadResource(notification.Resource.Id, true));
    },
    [notificationType.ChannelRenamed]: notification => {
      if (hasWorkSpaceId(notification)) {
        store.dispatch(loadSpace(notification.Workspace.Id));
        addNotificationToStore(notification);
      }
    },
    [notificationType.ChannelFilteredEvent]: notification => {
      if (hasWorkSpaceId(notification)) {
        store.dispatch(
          archiveSpace(
            notification.Workspace.Id,
            notification.Workspace.Filtered
          )
        );
      }
    },
    [notificationType.ChannelResourceWritabilityChanged]: notification => {
      if (hasWorkSpaceId(notification)) {
        store.dispatch(
          setLinkedItemLocked(
            notification.Resource.Id,
            !notification.Resource.Writable
          )
        );
        addNotificationToStore(notification);
      }
    },
    [notificationType.ChannelInvitation]: notification => {
      if (hasWorkSpaceId(notification)) {
        // show the new space in the list of spaces
        addNotificationToStore(notification);
        store.dispatch(loadSpace(notification.Workspace.Id));
      }
    },
    [notificationType.ChannelLinkRenamed]: notification => {
      addNotificationToStore(notification);
      store.dispatch(loadResource(notification.Resource.Id, true));
    },
    [notificationType.ChannelResourceDownloaded]: notification => {
      addNotificationToStore(notification);
    },
    [notificationType.GWorkspaceReward]: notification => {
      addNotificationToStore(notification);
    },
    [notificationType.ShareTaskEvent]: notification => {
      addNotificationToStore(notification);
    },
    [notificationType.UserAvatarChanged]: notification => {
      store.dispatch(
        userAvatarChanged(
          notification.User.Email,
          notification.AvatarUrl,
          notification.Color
        )
      );
    },
    [notificationType.ChannelAvatarChanged]: notification => {
      store.dispatch(
        changeSpaceAvatar(notification.Workspace.Id, notification.AvatarUrl)
      );
    },
    [notificationType.WorkspaceCreated]: () => {
      store.dispatch(loadWorkspaces());
    },
    [notificationType.WorkspaceDeleted]: () => {
      store.dispatch(loadWorkspaces());
    },
    [notificationType.WorkspaceMemberJoinedEvent]: notification => {
      /*
        If the current user was just added to a work space, fetch the list of work spaces. 
        This doesn't happen very often, so it is not expensive to simply fetch the work spaces from the server.
      */
      store.dispatch(loadWorkspaces());
      store.dispatch(loadRecentlyAddedWorkspaces());
      reloadWorkSpace(notification.WorkspaceMember.WorkspaceId);
    },
    [notificationType.WorkspaceMemberInvitedEvent]: notification => {
      reloadWorkSpace(notification.WorkspaceMember.WorkspaceId);
    },
    [notificationType.WorkspaceMemberPendingEvent]: notification => {
      reloadWorkSpace(notification.WorkspaceMember.WorkspaceId);
    },
    [notificationType.WorkspaceMemberLeftEvent]: notification => {
      reloadWorkSpace(notification.WorkspaceMember.WorkspaceId);
    },
    [notificationType.WorkspacepMemberRejectedEvent]: notification => {
      reloadWorkSpace(notification.WorkspaceMember.WorkspaceId);
    },
    [notificationType.WorkspaceMemberRemovedEvent]: notification => {
      reloadWorkSpace(notification.WorkspaceMember.WorkspaceId);
    },
    [notificationType.WorkspaceInvitation]: () => {
      store.dispatch(loadWorkspaces());
    },
    [notificationType.WorkspaceUpdate]: notification => {
      store.dispatch(loadWorkspaces());
      reloadWorkSpace(notification.Workspace.Id);
    },
    [notificationType.BandwidthUpdatedEvent]: notification => {
      store.dispatch(
        bandwidthChanged(
          notification.Bandwidth.Used,
          notification.Bandwidth.BandwidthLimit
        )
      );
    },
    [notificationType.ChannelLockChanged]: notification => {
      setTimeout(
        () => {
          store.dispatch(
            lockSpace(notification.Workspace.Id, notification.Locked)
          );
        },
        notification.Locked ? 0 : 5000
      );
      // The migration is usually so fast, the migration screen only shows the In Progress
      // state for a few ms. If we fake a 5 second interval, the user will see the In Progress state
      // for a meaningful amount of time.
    },
    [notificationType.TypingStarted]: notification => {
      store.dispatch(typingStarted(notification.Email, notification.ChannelId));
    },
    [notificationType.TypingStopped]: notification => {
      store.dispatch(typingStopped(notification.Email));
    },
    [notificationType.ReadStateChanged]: notification => {
      store.dispatch(
        readStateChanged(
          notification.ChannelId,
          notification.Email,
          notification.NotificationId,
          notification.LastReadTimestamp
        )
      );
    },
    [notificationType.MeetingEnded]: notification => {
      if (notification.payload?.roomId) {
        store.dispatch(activeMeetingEnded(notification.payload.roomId));
      }
    },
    [notificationType.MeetingInvitationV2]: notification => {
      store.dispatch(meetingInvitationReceived(notification.payload));
      // TODO send ack to server
    },
    [notificationType.MeetingChangedV2]: notification => {
      for (const meeting of notification.payload) {
        store.dispatch(activeMeetingUpdated(meeting));
      }
    },
    [notificationType.HideMeetingInvitation]: notification => {
      store.dispatch(hideMeetingInvitation(notification.payload.roomId));
    },
    [notificationType.TaggedResourceChanged]: notification => {
      next(tagDataChanged(notification.payload));
    },
    [notificationType.ChannelResourceTagChanged]: notification => {
      next(tagDataChanged(notification.payload));
    },
    [notificationType.ChannelResourceTagDeleted]: notification => {
      next(tagDataChanged(notification.payload));
    },
    [notificationType.NoteUpdated]: notification => {
      next(noteDataChanged(notification.payload));
    },
    [notificationType.RealTimeNoteUpdated]: notification => {
      next(realTimeNoteDataChanged(notification.payload));
    },
  };

  const handleNotification = message => {
    const parseNotification = notification => {
      try {
        return JSON.parse(notification);
      } catch (error) {
        return null;
      }
    };

    const notification = parseNotification(message);

    if (!notification) {
      return;
    }

    // reload the spaces so the last activity date is updated
    if (notification.Workspace) {
      store.dispatch(
        updateLastUpdatedTimestamp(notification.Workspace.Id, Date.now())
      );
    }

    const notificationType = notification.Type || notification.type;

    try {
      notificationHandlers[notificationType](notification);
    } catch (exception) {
      console.debug(`${notificationType} not supported`);
      console.debug(notification);
      console.debug(exception);
    }
  };

  const sendMessage = (message, retryNumber = 0) => {
    if (retryNumber > SEND_MESSAGE_MAX_ATTEMPS) {
      return;
    }
    const finalMessage = isPlainObject(message)
      ? JSON.stringify(message)
      : message;
    if (canSendMessages) {
      websocket.send(finalMessage);
    } else {
      setTimeout(
        () => sendMessage(finalMessage, (retryNumber += 1)),
        SEND_MESSAGE_INTERVAL
      );
    }
  };

  return action => {
    if (action.type === CONNECT_WEB_SOCKET) {
      setupWebsocket(action.url);
    } else if (action.type === SEND_WEB_SOCKET_MESSAGE) {
      sendMessage(action.payload);
    }

    return next(action);
  };
};
