import PropTypes from 'prop-types';
import { useState } from 'react';
import { Select, Form, Checkbox, Button } from 'semantic-ui-react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { sortBy, uniqBy } from 'lodash';
import { useDebounce } from 'use-debounce';

import { useContacts } from 'Hooks/data/contacts/useContacts';

import Avatar from 'Components/Collaborators/Avatar';
import SpaceIcon from 'Components/Spaces/SpaceIcon';
import EnterMultipleEmailModal from 'Components/Modals/WorkspaceMember/EnterMultipleEmailModal';

const style = { marginRight: 10 };

export const CreateMembersMeetingNew = ({
  chats,
  defaultWorkspaceId,
  onWorkspaceSelected,
  onSelectAllMembers,
  showSelectAllMembersOption,
  showLoader,
  selectedChats,
  onSelectedChatsChange,
  allChannelMembersSelected,
  intl,
}) => {
  const [showBulkAddEmailModal, setShowBulkAddEmailModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);
  const [additionalContacts, setAdditionalContacts] = useState([]);

  const onSearchChange = (event, { searchQuery }) => {
    setSearchQuery(searchQuery);
  };

  const { searchedContacts, isFetching } = useContacts(
    debouncedSearchQuery,
    defaultWorkspaceId
  );

  const chatsFinal = chats.concat(searchedContacts).concat(additionalContacts);

  const chatsFilteredUnique = uniqBy(chatsFinal, 'collaboratorUserId');

  const createContactFromEmailAddress = email => ({
    Id: email,
    collaboratorUserId: email,
    Email: email,
    external: true,
  });

  const handleAddition = (e, { value }) => {
    setAdditionalContacts(additionalContacts =>
      additionalContacts.concat(createContactFromEmailAddress(value))
    );
  };

  const onChange = (event, data) => {
    /**
     * prioritize the current teamspace
     */
    const sortedChats = sortBy(chatsFinal, chat =>
      chat.WorkspaceId === defaultWorkspaceId ? 0 : 1
    );
    onSelectedChatsChange(
      data.value.map(
        value =>
          sortedChats.find(chat => chat.collaboratorUserId === value) ||
          createContactFromEmailAddress(value)
      )
    );
  };

  const onMultipleEmailAdded = emails => {
    const contacts = emails.map(email => createContactFromEmailAddress(email));

    setAdditionalContacts(additionalContacts =>
      additionalContacts.concat(contacts)
    );

    onSelectedChatsChange(selectedChats.concat(contacts));

    setShowBulkAddEmailModal(false);
  };

  return (
    <>
      {showBulkAddEmailModal && (
        <EnterMultipleEmailModal
          onClose={() => {
            setShowBulkAddEmailModal(false);
          }}
          onSubmit={onMultipleEmailAdded}
        />
      )}
      <Form.Field>
        <label htmlFor="members">
          <FormattedMessage id="CreateMembersMeeting.Members" />
        </label>
        <Select
          allowAdditions
          additionLabel={
            <FormattedMessage id="CreateMembersMeetingNew.InviteExternalEmail" />
          }
          onAddItem={handleAddition}
          selectOnBlur={false}
          search
          loading={isFetching}
          multiple
          fluid
          placeholder={intl.formatMessage({
            id: 'CreateMembersMeetingNew.placeholder',
          })}
          noResultsMessage={
            isFetching
              ? null
              : intl.formatMessage({
                  id: 'noResultsMessage',
                })
          }
          onSearchChange={onSearchChange}
          onChange={onChange}
          options={chatsFilteredUnique.map(chat => ({
            key: chat.collaboratorUserId,
            text: chat.Name,
            value: chat.collaboratorUserId,
            chat,
            content: (
              <div className="flex align-items-center">
                {chat.fromApi || chat.external ? (
                  <Avatar
                    avatarUrl={chat.AvatarUrl}
                    color={chat.Color}
                    email={chat.Email}
                    firstName={chat.FirstName}
                    lastName={chat.LastName}
                    presence={chat.Presence}
                    showStatus
                    width={30}
                    height={30}
                    style={style}
                  />
                ) : (
                  <SpaceIcon space={chat} width={30} height={30} showStatus />
                )}
                <span className="mx-1">{chat.Name || chat.Email}</span>
              </div>
            ),
          }))}
          renderLabel={item => {
            return {
              content: (
                <span>
                  {item.chat.fromApi || item.chat.external ? (
                    <Avatar
                      avatarUrl={item.chat.AvatarUrl}
                      color={item.chat.Color}
                      email={item.chat.Email}
                      firstName={item.chat.FirstName}
                      lastName={item.chat.LastName}
                      presence={item.chat.Presence}
                      showStatus
                      width={26}
                      height={26}
                    />
                  ) : (
                    <SpaceIcon
                      space={item.chat}
                      width={26}
                      height={26}
                      showStatus
                    />
                  )}
                  {item.chat.Name || item.chat.Email}
                </span>
              ),
            };
          }}
          value={selectedChats
            .filter(chat => chat)
            .map(chat => chat.collaboratorUserId)}
        />
        <div className="flex center space-between mt-2">
          {showSelectAllMembersOption && (
            <Checkbox
              size="mini"
              checked={allChannelMembersSelected}
              onChange={onSelectAllMembers}
              label={{
                children: (
                  <FormattedMessage id="CreateMembersMeeting.SelectAllChannelMembers" />
                ),
              }}
            />
          )}
          <Button
            basic
            compact
            size="small"
            type="button"
            onClick={() => {
              setShowBulkAddEmailModal(true);
            }}
          >
            <FormattedMessage id="CreateScheduled.or-add-multiple-e-mail-addresses" />
          </Button>
        </div>
      </Form.Field>
    </>
  );
};

CreateMembersMeetingNew.propTypes = {
  selectedChats: PropTypes.array.isRequired,
  defaultWorkspaceId: PropTypes.string.isRequired,
  onSelectedChatsChange: PropTypes.func.isRequired,
  allChannelMembersSelected: PropTypes.bool,
  chats: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(CreateMembersMeetingNew);
