import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { memo } from 'react';

import { REMINDER_TYPE } from 'DataLayer/Reminders/get';
import {
  showAddReminderModal,
  showSaveReminderModal,
} from 'store/Modals/actions';
import WeTeamIcon from 'Components/Icons/WeTeamIcon';
import { TimeSquareIcon } from 'Components/Icons/WeTeamIcon';
import DisableInBackupMode from 'Components/BackupMode/DisableInBackupMode';

const AddReminderButton = ({
  children,
  reminderId,
  spaceId,
  targetId,
  onAddReminder,
  onEditReminder,
  reminderType,
  target,
  ...props
}) => {
  const onClick = e => {
    e.stopPropagation();
    if (reminderId) {
      onEditReminder(reminderId);
    } else {
      onAddReminder(spaceId, reminderType, targetId, target);
    }
  };
  return children({
    icon: (
      <DisableInBackupMode>
        <WeTeamIcon
          active={!!reminderId}
          Icon={TimeSquareIcon}
          onClick={onClick}
          {...props}
        />
      </DisableInBackupMode>
    ),
    onClick,
  });
};

AddReminderButton.propTypes = {
  children: PropTypes.func.isRequired,
  reminderId: PropTypes.string,
  spaceId: PropTypes.string.isRequired,
  targetId: PropTypes.string,
  target: PropTypes.object,
  onAddReminder: PropTypes.func.isRequired,
  onEditReminder: PropTypes.func.isRequired,
  reminderType: PropTypes.oneOf([
    REMINDER_TYPE.linkedItem,
    REMINDER_TYPE.resource,
    REMINDER_TYPE.notification,
    REMINDER_TYPE.comment,
  ]).isRequired,
};

export default connect(undefined, {
  onAddReminder: showAddReminderModal,
  onEditReminder: showSaveReminderModal,
})(
  memo(AddReminderButton, (prev, next) => {
    // update only required if the reminderId changes. i.e a reminder is added/removed
    return prev.reminderId === next.reminderId;
  })
);
