import { FormattedMessage } from 'react-intl';

import React, { memo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import distanceInWords from 'date-fns/distance_in_words';
import distanceInWordsLocaleDe from 'date-fns/locale/de';
import collaboratorPresence from 'Constants/collaboratorPresence';

import { getLocale } from 'store/Locale/selectors';
import { supportedLocales } from 'translations/getMessageByLocale';

function CollaboratorLastSeen({ date, presence, locale }) {
  if (!date) {
    return null;
  }

  if (presence === collaboratorPresence.ONLINE) {
    return (
      <span>
        <FormattedMessage id="CollaboratorLastSeen.online-now" />
      </span>
    );
  }

  const lastSeen = distanceInWords(format(new Date()), format(date), {
    locale: locale === supportedLocales.de ? distanceInWordsLocaleDe : null,
    addSuffix: true,
  });
  return (
    <span>
      <FormattedMessage
        id="CollaboratorLastSeen.last-seen-ago"
        values={{ lastSeen }}
      />
    </span>
  );
}

CollaboratorLastSeen.propTypes = {
  date: PropTypes.number,
  presence: PropTypes.string,
  locale: PropTypes.string.isRequired,
};

export default connect(state => ({
  locale: getLocale(state),
}))(memo(CollaboratorLastSeen));
