import axios from 'axios';
import { getMeetingUsersPutUrl } from 'DataLayer/endpoints';
import { getOtixoBackendApiUrl } from '../../../config/config';

export function put(meetingId, participants) {
  const config = {
    baseURL: getOtixoBackendApiUrl(),
  };

  return axios
    .put(getMeetingUsersPutUrl(meetingId), participants, config)
    .then(response => response.data);
}
