import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Modal, Header, Button, Message, Dimmer } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import uniqBy from 'lodash/uniqBy';

import { getUserGuid } from 'store/User/selectors';
import { updateParticipants } from 'store/Meeting/Users/updateParticipants';
import { getOrderedChatsByWorkspaceId } from 'store/Spaces/selectors';

import useAsyncFn from 'Hooks/useAsyncFn';

import ErrorBoundary from 'Components/ErrorBoundaries/ErrorBoundary';
import CreateMembersMeetingNew from 'Components/Modals/Meeting/CreateMeetingModal/CreateMembersMeetingNew';
import MeetingUserAvatars from 'Components/Meeting/MeetingUserAvatars';
import ParticipantAvatars from 'Components/Meeting/ParticipantAvatars';

const ManageParticipantsModal = ({ meeting, chats, onClose }) => {
  const userGuid = useSelector(getUserGuid);

  const meetingUsers = meeting.participants
    .filter(p => p.participantId !== userGuid)
    .map(p => {
      return {
        ...p,
        Id: p.participantId,
        UserId: p.participantId,
        collaboratorUserId: p.participantId,
        FirstName: p.firstName,
        LastName: p.lastName,
        Name: p.firstName ? `${p.firstName} ${p.lastName}` : p.email,
        Email: p.email,
        AvatarUrl: p.avatarUrl,
        Color: p.color,
        Presence: p.presence,
        fromApi: true,
      };
    });

  const chatsFinalUnique = uniqBy(
    chats.concat(meetingUsers),
    'collaboratorUserId'
  );

  const dispatch = useDispatch();
  const [selectedChats, setSelectedChats] = useState(
    meeting.participants
      .filter(p => p.invite)
      .map(p =>
        chatsFinalUnique.find(
          chat => chat.collaboratorUserId === p.participantId
        )
      )
      .filter(p => p)
  );
  const invitedParticipantIds = useMemo(
    () => meeting.participants.filter(p => p.invite).map(p => p.participantId),
    [meeting.participants]
  );

  const participantIdsToRemove = useMemo(() => {
    //find invited participants who are no longer in selectedChats
    const selectedChatIds = selectedChats.map(chat => chat.collaboratorUserId);

    //find selectedChats who are not in invited participants
    return invitedParticipantIds.filter(
      invitedUserId => !selectedChatIds.includes(invitedUserId)
    );
  }, [invitedParticipantIds, selectedChats]);

  const participantsToInvite = useMemo(
    () =>
      selectedChats.filter(
        selectedChat =>
          !invitedParticipantIds.includes(selectedChat.collaboratorUserId)
      ),
    [invitedParticipantIds, selectedChats]
  );

  const [sendInvitationRequest, onSendInvitation] = useAsyncFn(async () => {
    return await dispatch(
      updateParticipants(
        meeting.roomId,
        participantsToInvite,
        participantIdsToRemove
      )
    );
  }, [dispatch, meeting, participantIdsToRemove, participantsToInvite]);

  return (
    <Modal size="tiny" open onClose={onClose}>
      <Header>
        <FormattedMessage id="ManageParticipants.Title" />
        <div className="close_modal">
          <button className="close_modal_button" onClick={onClose} />
        </div>
      </Header>
      <Modal.Content>
        <div className="mb-3">
          <MeetingUserAvatars meeting={meeting} />
        </div>
        <div className="mb-3">
          <ParticipantAvatars meeting={meeting} />
        </div>
        <Dimmer.Dimmable as={'div'} dimmed={sendInvitationRequest.loading}>
          <Dimmer active={sendInvitationRequest.loading} inverted />
          <ErrorBoundary>
            <CreateMembersMeetingNew
              defaultWorkspaceId={meeting.workspaceId}
              selectedChats={selectedChats}
              chats={chatsFinalUnique}
              onSelectedChatsChange={setSelectedChats}
            />
          </ErrorBoundary>
          {sendInvitationRequest.error && (
            <Message error>
              <FormattedMessage id="ManageParticipantsModal.Error" />
            </Message>
          )}
        </Dimmer.Dimmable>
        <div className="modal-buttons">
          <Button
            primary
            onClick={onSendInvitation}
            loading={sendInvitationRequest.loading}
            disabled={
              sendInvitationRequest.loading ||
              (!participantIdsToRemove.length && !participantsToInvite.length)
            }
          >
            <FormattedMessage id="ManageParticipantsModal.UpdateButtonText" />
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

ManageParticipantsModal.propTypes = {
  meeting: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default connect(() => {
  return (state, props) => {
    return {
      chats: getOrderedChatsByWorkspaceId(state, props.meeting.workspaceId),
    };
  };
})(ManageParticipantsModal);
