import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { Transition, Segment, Header, Message } from 'semantic-ui-react';
import * as Sentry from '@sentry/react';

import { isDesktopApp } from 'config/config';

import CallerAvatar from 'Components/Meeting/CallerAvatar';
import MeetingsRestrictedMessage from 'Components/Meeting/MeetingsRestrictedMessage';
import ParticipantAvatars from 'Components/Meeting/ParticipantAvatars';
import JoinMeetingButton from 'Components/Meeting/JoinMeetingButton';
import RejectMeetingButton from 'Components/Meeting/RejectMeetingButton';
import CurrentMeetingWillEndMessage from 'Components/Meeting/CurrentMeetingWillEndMessage';
import MeetingUserAvatars from 'Components/Meeting/MeetingUserAvatars';
import MeetingStarted from 'Components/Meeting/MeetingStarted';
import MeetingLocation from 'Components/Meeting/MeetingLocation';
import { createDesktopNotification } from 'Helpers/BrowserApi/desktopNotification';

import styles from './MeetingInvitation.module.scss';

export function MeetingInvitation({ activeMeeting, intl }) {
  const title = useMemo(() => {
    try {
      return intl.formatMessage(
        {
          id: 'MeetingInvitation.Title',
        },
        {
          caller: activeMeeting.caller
            ? `${activeMeeting.caller?.firstName} ${activeMeeting.caller?.lastName}`
            : '',
        }
      );
    } catch (e) {
      Sentry.captureException(e);
      return '';
    }
  }, [activeMeeting.caller, intl]);

  const message = useMemo(() => {
    try {
      const time = intl.formatDate(activeMeeting.meetingStartedAt, {
        hour: '2-digit',
        minute: '2-digit',
      });
      return `${activeMeeting.workspaceName} > ${activeMeeting.channelName} @ ${time}`;
    } catch (e) {
      Sentry.captureException(e);
      return '';
    }
  }, [
    activeMeeting.channelName,
    activeMeeting.meetingStartedAt,
    activeMeeting.workspaceName,
    intl,
  ]);

  const showDesktopNotification = useCallback(() => {
    const run = async () => {
      if (isDesktopApp()) {
        window.ipcRenderer?.send('desktop-notification', {
          title,
          message,
        });
      } else {
        const desktopNotification = await createDesktopNotification({
          title,
          message,
          requireInteraction: true,
        });
        desktopNotification.onclick = e => {
          window.focus();
          desktopNotification.close();
        };
      }
    };
    try {
      run();
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [message, title]);

  useEffect(() => {
    showDesktopNotification();
  }, [showDesktopNotification]);

  return (
    <Transition visible transitionOnMount animation="fly down" duration={500}>
      <Segment.Group data-testid="meeting-invitation">
        <Header
          as="h3"
          attached="top"
          className="flex center justify-content-space-between"
        >
          {title}
        </Header>
        <Segment attached data-testid="segment">
          <div className={styles.meetingInvitation}>
            <span className="mr-3">
              <CallerAvatar meeting={activeMeeting} />
            </span>
            <div className={styles.info}>
              <p>
                <MeetingLocation meeting={activeMeeting} />
              </p>
              <p>
                <MeetingStarted meeting={activeMeeting} />
              </p>
              <p>
                <MeetingUserAvatars meeting={activeMeeting} />
              </p>
              <p>
                <ParticipantAvatars meeting={activeMeeting} />
              </p>
            </div>
          </div>
          <CurrentMeetingWillEndMessage roomId={activeMeeting.roomId} />
          <MeetingsRestrictedMessage
            As={Message}
            meeting={activeMeeting}
            showUpgradeButton
          />
          <div className="flex justify-content-space-between">
            <RejectMeetingButton roomId={activeMeeting.roomId} />
            <JoinMeetingButton roomId={activeMeeting.roomId} />
          </div>
        </Segment>
      </Segment.Group>
    </Transition>
  );
}

MeetingInvitation.propTypes = {
  activeMeeting: PropTypes.shape({
    roomId: PropTypes.string.isRequired,
    participants: PropTypes.array.isRequired,
    caller: PropTypes.object.isRequired,
  }).isRequired,
  intl: intlShape,
};

export default injectIntl(MeetingInvitation);
