import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { getIsWorkspaceMarkedAsUnread } from 'store/UI/selectors';
import { Label } from 'semantic-ui-react';
import NotificationBadge from 'Components/Badges/NotificationBadge';

function UnreadMessagesBadge({
  isWorkspaceMarkedAsUnread,
  numberOfNotifications,
}) {
  if (numberOfNotifications > 0) {
    return <NotificationBadge count={numberOfNotifications} floating={false} />;
  }
  if (isWorkspaceMarkedAsUnread) {
    return <Label circular color="red" size="tiny" className="ml-2" />;
  }
  return null;
}

UnreadMessagesBadge.propTypes = {
  isWorkspaceMarkedAsUnread: PropTypes.bool,
  numberOfNotifications: PropTypes.number.isRequired,
};

export default connect((state, props) => {
  return {
    isWorkspaceMarkedAsUnread: getIsWorkspaceMarkedAsUnread(
      state,
      props.workspaceId
    ),
  };
})(UnreadMessagesBadge);
