import { convertArrayToObject } from 'Helpers/Array/convertArrayToObject';

export const MEETING_SERVER_URL_RECEIVED = 'MEETING_SERVER_URL_RECEIVED';
export const MEETING_ACTIVE_HEARTBEAT = 'MEETING_ACTIVE_HEARTBEAT';
export const MEETING_LEFT = 'MEETING_LEFT';
export const MEETING_JOINED = 'MEETING_JOINED';
export const MEETING_STARTING = 'MEETING_STARTING';
export const MEETING_START_REQUESTED = 'MEETING_START_REQUESTED';
export const MEETING_ENDED = 'MEETING_ENDED';
export const MEETING_HIDDEN = 'MEETING_HIDDEN';
export const MEETING_SHOWN = 'MEETING_SHOWN';
export const MEETING_VIDEO_MUTED = 'MEETING_VIDEO_MUTED';
export const MEETING_AUDIO_MUTED = 'MEETING_AUDIO_MUTED';
export const MEETING_SCREEN_SHARED = 'MEETING_SCREEN_SHARED';
export const MEETING_HANG_UP_REQUESTED = 'MEETING_HANG_UP_REQUESTED';
export const MEETING_INVITATION_REJECTED = 'MEETING_INVITATION_REJECTED';
export const MEETING_INVITATION_ACCEPTED = 'MEETING_INVITATION_ACCEPTED';
export const ACTIVE_MEETING_ENDED = 'ACTIVE_MEETING_ENDED';
export const MEETING_INVITATION_RECEIVED = 'MEETING_INVITATION_RECEIVED';
export const ACTIVE_MEETING_UPDATED = 'MEETING_UPDATED';
export const HIDE_MEETING_INVITATION = 'HIDE_MEETING_INVITATION';
export const SHOW_MEETING = 'SHOW_MEETING';

export const MEETING_STATUS = {
  REJECTED: 'REJECTED',
  INVITED: 'INVITED',
  JOINED: 'JOINED',
  JOINING: 'JOINING',
  STARTING: 'STARTING',
  HANG_UP_REQUESTED: 'HANG_UP_REQUESTED',
};

export function showMeeting() {
  return {
    type: SHOW_MEETING,
  };
}

export function meetingServerUrlReceived(meetingServerUrl) {
  return {
    type: MEETING_SERVER_URL_RECEIVED,
    meetingServerUrl,
  };
}

export function hideMeetingInvitation(roomId) {
  return {
    type: HIDE_MEETING_INVITATION,
    roomId,
  };
}

export function meetingActiveHeartbeat(roomId, localParticipantId) {
  return {
    type: MEETING_ACTIVE_HEARTBEAT,
    roomId,
    localParticipantId,
  };
}

export function activeMeetingUpdated(meeting) {
  return {
    type: ACTIVE_MEETING_UPDATED,
    meeting,
  };
}

export function meetingInvitationReceived(meeting) {
  return {
    type: MEETING_INVITATION_RECEIVED,
    meeting: {
      ...meeting,
      state: MEETING_STATUS.INVITED,
    },
  };
}

export function meetingInvitationRejected(roomId) {
  return {
    type: MEETING_INVITATION_REJECTED,
    roomId,
  };
}

export function meetingJoined(roomId, localParticipantId) {
  return {
    type: MEETING_JOINED,
    roomId,
    localParticipantId,
  };
}

export function meetingLeft(roomId, localParticipantId) {
  return {
    type: MEETING_LEFT,
    roomId,
    localParticipantId,
  };
}

export function meetingStarting(roomId, lobbyEnabled, password) {
  return {
    type: MEETING_STARTING,
    roomId,
    lobbyEnabled,
    password,
  };
}

export function meetingStartRequested(roomId, lobbyEnabled, password) {
  return {
    type: MEETING_START_REQUESTED,
    roomId,
    lobbyEnabled,
    password,
  };
}

export function meetingEnded() {
  return {
    type: MEETING_ENDED,
  };
}

export function meetingHangUpRequested() {
  return {
    type: MEETING_HANG_UP_REQUESTED,
  };
}

export function meetingHidden() {
  return {
    type: MEETING_HIDDEN,
  };
}

export function meetingShown() {
  return {
    type: MEETING_SHOWN,
  };
}

export function audioMuted(muted) {
  return {
    type: MEETING_AUDIO_MUTED,
    muted,
  };
}

export function videoMuted(muted) {
  return {
    type: MEETING_VIDEO_MUTED,
    muted,
  };
}

export function screenShared(isScreenShared) {
  return {
    type: MEETING_SCREEN_SHARED,
    isScreenShared,
  };
}

export function meetingInvitationAccepted(roomId) {
  return {
    type: MEETING_INVITATION_ACCEPTED,
    roomId,
  };
}

export function activeMeetingEnded(roomId) {
  return {
    type: ACTIVE_MEETING_ENDED,
    roomId,
  };
}

export const convertActiveMeetingsArrayToObject = activeMeetings =>
  convertArrayToObject(activeMeetings, 'roomId');

export const intitialState = {
  status: null,
  roomId: null,
  isAudioMuted: null,
  isVideoMuted: null,
  isScreenShared: null,
  activeMeetings: {},
  meetingServerUrl: null,
};

export default function (state = intitialState, action) {
  switch (action.type) {
    case MEETING_SERVER_URL_RECEIVED: {
      return {
        ...state,
        meetingServerUrl: action.meetingServerUrl,
      };
    }
    case HIDE_MEETING_INVITATION: {
      /*
        Only set the state of the meeting to NULL if the meeting
        is in state INVITED
        If the meeting is in a different state e.g JOINING, the invitation
        has already been hidden
      */
      if (
        state.activeMeetings[action.roomId]?.state !== MEETING_STATUS.INVITED
      ) {
        return state;
      }
      return {
        ...state,
        activeMeetings: {
          ...state.activeMeetings,
          [action.roomId]: {
            ...state.activeMeetings?.[action.roomId],
            state: null,
          },
        },
      };
    }
    case ACTIVE_MEETING_UPDATED: {
      return {
        ...state,
        activeMeetings: {
          ...state.activeMeetings,
          [action.meeting.roomId]: {
            ...state.activeMeetings?.[action.meeting.roomId],
            ...action.meeting,
          },
        },
      };
    }
    case MEETING_INVITATION_RECEIVED: {
      /*
        It's possible this event will be dispatched between the user clicking join
        and the user joining the meeting (it takes a few seconds for the Jitsi meeting
          to initialise)
        If the user is JOINING the meeting, we prevent the state being switched
        back to INVITED, because this would display the meeting invitation in the UI again.
      */
      if (
        state.activeMeetings[action.meeting.roomId]?.state ===
        MEETING_STATUS.JOINING
      ) {
        return state;
      }

      return {
        ...state,
        activeMeetings: {
          ...state.activeMeetings,
          [action.meeting.roomId]: action.meeting,
        },
      };
    }
    case MEETING_INVITATION_REJECTED: {
      const newActiveMeetings = { ...state.activeMeetings };
      newActiveMeetings[action.roomId].state = MEETING_STATUS.REJECTED;
      return {
        ...state,
        activeMeetings: newActiveMeetings,
      };
    }
    case ACTIVE_MEETING_ENDED: {
      const newActiveMeetings = { ...state.activeMeetings };
      delete newActiveMeetings[action.roomId];
      return {
        ...state,
        activeMeetings: newActiveMeetings,
      };
    }
    case MEETING_INVITATION_ACCEPTED: {
      const newActiveMeetings = { ...state.activeMeetings };
      newActiveMeetings[action.roomId].state = MEETING_STATUS.JOINING;
      return {
        ...state,
        activeMeetings: newActiveMeetings,
      };
    }
    case MEETING_HANG_UP_REQUESTED:
      return {
        ...state,
        status: MEETING_STATUS.HANG_UP_REQUESTED,
      };
    case MEETING_JOINED:
      const newActiveMeetings = { ...state.activeMeetings };
      if (!newActiveMeetings[action.roomId]) {
        return {
          ...state,
          status: MEETING_STATUS.JOINED,
          localParticipantId: action.localParticipantId,
        };
      }
      newActiveMeetings[action.roomId].state = MEETING_STATUS.JOINED;
      return {
        ...state,
        activeMeetings: newActiveMeetings,
        status: MEETING_STATUS.JOINED,
        localParticipantId: action.localParticipantId,
      };
    case MEETING_STARTING:
      // prevent a 2nd meeting from starting
      if (state.status === MEETING_STATUS.JOINED) {
        return state;
      }
      return {
        ...state,
        status: MEETING_STATUS.STARTING,
        roomId: action.roomId,
      };
    case MEETING_ENDED:
      return {
        ...state,
        status: undefined,
        roomId: undefined,
      };
    case MEETING_AUDIO_MUTED:
      return {
        ...state,
        isAudioMuted: action.muted,
      };
    case MEETING_VIDEO_MUTED:
      return {
        ...state,
        isVideoMuted: action.muted,
      };
    case MEETING_SCREEN_SHARED:
      return {
        ...state,
        isScreenShared: action.isScreenShared,
      };
    default:
      return state;
  }
}
