import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field as FormikField } from 'formik';
import * as yup from 'yup';
import { Message, Form, Radio, Button } from 'semantic-ui-react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import { captureException } from 'Helpers/sentry';

import {
  configureRecurly,
  getCVVLength,
  getVATNumberPlaceholderByCountryCode,
  handleThreeDSecure,
  isCardError,
  isCVVError,
  isPostcodeError,
  isTaxableRegion,
  isVATError,
} from 'Helpers/Recurly/recurly';
import { ThreeDSecureError } from 'Helpers/Recurly/ThreeDSecureError';

import PageTransition from 'Components/Transitions/PageTransition';

import './RecurlyPaymentForm.scss';
import {
  countriesEn,
  getCountryListByLocale,
  isUnitedStates,
} from 'Helpers/Recurly/countries';
import { connect } from 'react-redux';
import { getLocale } from 'store/Locale/selectors';
import classNames from 'classnames/bind';
import { withPrefersColorScheme } from 'Components/Utilities/withPrefersColorScheme';

class RecurlyPaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.threeDSecureRef = React.createRef();
  }
  componentDidMount() {
    const { intl, lightScheme } = this.props;
    configureRecurly();
    this.elements = window.recurly.Elements();
    const cardElement = this.elements.CardElement({
      style: {
        fontColor: lightScheme ? '#212529' : 'white',
        placeholder: {
          content: {
            number: intl.formatMessage({
              id: 'RecurlyPaymentForm.card-number',
            }),
            cvv: intl.formatMessage({ id: 'RecurlyPaymentForm.cvv' }),
            expiry: intl.formatMessage({ id: 'RecurlyPaymentForm.expiry' }),
          },
        },
      },
    });
    cardElement.attach('#recurly-elements');
  }

  onRecurlyTokenReceived = (billingInfoTokenId, actionResultTokenId) => {
    return new Promise((resolve, reject) => {
      this.props
        .onRecurlyTokenReceived(billingInfoTokenId, actionResultTokenId)
        .catch(error => {
          // if 3d secure error
          // https://developers.recurly.com/guides/3ds2.html#integration-guide
          if (error instanceof ThreeDSecureError) {
            handleThreeDSecure(
              this.threeDSecureRef.current,
              error.threeDSecureTokenId
            )
              .then(threeDSecureResultTokenId => {
                this.onRecurlyTokenReceived(
                  billingInfoTokenId,
                  threeDSecureResultTokenId
                )
                  .then(resolve)
                  .catch(reject);
              })
              .catch(threedSecureError => {
                this.props.onError(threedSecureError);
                reject();
                captureException(threedSecureError);
              });
          } else {
            this.props.onError(error);
            reject();
          }
          captureException(error);
        });
    });
  };

  render() {
    const {
      cardExpiring,
      intl,
      billingInformation,
      plan,
      quantity,
      isAddingSubscription,
      submitButtonText = (
        <FormattedMessage id="RecurlyPaymentForm.SubmitButtonText" />
      ),
      dismissButton,
    } = this.props;
    const requiredText = intl.formatMessage({
      id: 'General.Form.Field.required',
    });

    const schema = yup.object().shape({
      companyName: yup.string().nullable(),
      businessPurchase: yup.bool(),
      firstName: yup.string().required(requiredText),
      lastName: yup.string().required(requiredText),
      address1: yup.string().required(requiredText),
      address2: yup.string(),
      city: yup.string().required(requiredText),
      state: yup.string().when('country', {
        is: countriesEn.US,
        then: yup.string(),
        otherwise: yup.string().required(requiredText),
      }),
      usState: yup.string().when('country', {
        is: countriesEn.US,
        then: yup.string().required(requiredText),
        otherwise: yup.string(),
      }),
      postal_code: yup.string().required(requiredText),
      country: yup.string().required(requiredText),
      vat_number: yup.string().nullable(),
    });

    return (
      <PageTransition>
        <div ref={this.threeDSecureRef} />
        <Formik
          initialErrors={{
            cardExpiring,
          }}
          initialValues={{
            companyName: billingInformation?.company,
            businessPurchase:
              billingInformation?.vatNumber || !billingInformation?.street1
                ? true
                : false,
            firstName: billingInformation?.firstName,
            lastName: billingInformation?.lastName,
            address1: billingInformation?.street1,
            address2: billingInformation?.street2,
            city: billingInformation?.city,
            state: billingInformation?.region,
            usState: billingInformation?.region,
            postal_code: billingInformation?.postalCode,
            country: this.props.countryCode || '',
            vat_number: billingInformation?.vatNumber,
          }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            setErrors({});
            window.recurly.token(
              this.elements,
              this.recurlyForm,
              (err, billingInfoToken) => {
                if (err) {
                  captureException(JSON.stringify(err));
                  setSubmitting(false);

                  if (isCardError(err)) {
                    setErrors({
                      card: true,
                    });
                  } else if (isPostcodeError(err)) {
                    setErrors({
                      postal_code: true,
                    });
                  } else if (isVATError(err)) {
                    setErrors({
                      vat_number: true,
                    });
                  } else if (isCVVError(err)) {
                    setErrors({
                      cvv: {
                        length: getCVVLength(err),
                      },
                    });
                  }
                } else {
                  // recurly.js has filled in the 'token' field, so now we can submit the
                  // form to your server; alternatively, you can access token.id and do
                  // any processing you wish
                  this.onRecurlyTokenReceived(billingInfoToken.id)
                    .then(() => {
                      setSubmitting(false);
                    })
                    .catch(() => {
                      setSubmitting(false);
                    });
                }
              }
            );
          }}
          render={({
            setFieldValue,
            handleChange,
            values,
            errors,
            touched,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              className={errors ? 'ui form error' : 'ui form'}
              onSubmit={handleSubmit}
              ref={form => {
                this.recurlyForm = form;
              }}
            >
              <input hidden id="plan" data-recurly="plan" defaultValue={plan} />
              <input
                hidden
                type="text"
                data-recurly="plan_quantity"
                id="plan-quantity"
                defaultValue={quantity}
              />
              <h3>
                <FormattedMessage
                  id={
                    values.businessPurchase
                      ? 'RecurlyPaymentForm.CompanySectionTitle'
                      : 'RecurlyPaymentForm.PersonalSectionTitle'
                  }
                />
              </h3>
              <Form.Field error={touched.country && errors.country}>
                <label htmlFor="country" data-testid="country">
                  <FormattedMessage id="RecurlyPaymentForm.country" />
                </label>
                <FormikField name="country">
                  {({
                    field, // { name, value, onChange, onBlur }
                  }) => (
                    <select
                      {...field}
                      className="ui dropdown"
                      id="country"
                      data-recurly="country"
                      value={values.country}
                      onChange={event => {
                        field.onChange(event);
                        this.props.onSelectedCountryCodeChange(
                          event.target.value
                        );
                      }}
                    >
                      <option value="">
                        {intl.formatMessage({
                          id: 'RecurlyPaymentForm.country-select',
                        })}
                      </option>
                      {Object.entries(
                        getCountryListByLocale(this.props.locale)
                      ).map(([code, name]) => (
                        <option key={code} value={code}>
                          {name}
                        </option>
                      ))}
                    </select>
                  )}
                </FormikField>
                {touched.country && errors.country && (
                  <div>{errors.country}</div>
                )}
              </Form.Field>
              {isTaxableRegion(values.country) && (
                <>
                  <Form.Field
                    error={touched.businessPurchase && errors.businessPurchase}
                  >
                    <label
                      htmlFor="businessPurchase"
                      data-testid="businessPurchase"
                    >
                      <FormattedMessage id="RecurlyPaymentForm.BusinessPurchase" />
                    </label>
                    <Radio
                      label={{
                        children: (
                          <FormattedMessage id="RecurlyPaymentForm.BusinessPurchase.Yes" />
                        ),
                      }}
                      data-testid="business-purchase-yes"
                      name="businessPurchase"
                      value={values.businessPurchase}
                      checked={values.businessPurchase}
                      onChange={(event, { name, value }) => {
                        setFieldValue(name, true);
                      }}
                    />
                    <br />
                    <Radio
                      label={{
                        children: (
                          <FormattedMessage id="RecurlyPaymentForm.BusinessPurchase.No" />
                        ),
                      }}
                      data-testid="business-purchase-no"
                      name="businessPurchase"
                      value={!values.businessPurchase}
                      checked={!values.businessPurchase}
                      onChange={(event, { name, value }) => {
                        setFieldValue(name, false);
                        setFieldValue('vat_number', '');
                        this.props.onVatNumberChange('');
                      }}
                    />
                  </Form.Field>
                  {values.businessPurchase && (
                    <Form.Field error={touched.vat_number && errors.vat_number}>
                      <label htmlFor="vat_number" data-testid="vat_number">
                        <FormattedMessage id="RecurlyPaymentForm.vat_number" />
                      </label>
                      <FormikField name="vat_number">
                        {({
                          field, // { name, value, onChange, onBlur }
                        }) => (
                          <input
                            {...field}
                            data-recurly="vat_number"
                            data-testid="vat_number"
                            placeholder={getVATNumberPlaceholderByCountryCode(
                              values.country
                            )}
                            type="text"
                            name="vat_number"
                            id="vat_number"
                            value={values.vat_number}
                            onChange={event => {
                              field.onChange(event);
                              this.props.onVatNumberChange(event.target.value);
                            }}
                          />
                        )}
                      </FormikField>
                      {touched.vat_number && errors.vat_number && (
                        <Message error data-testid="vat-number-error">
                          <FormattedMessage id="RecurlyPaymentForm.Errors.VATNumber" />
                        </Message>
                      )}
                    </Form.Field>
                  )}
                </>
              )}
              {values.businessPurchase && (
                <Form.Field error={touched.companyName && errors.companyName}>
                  <label htmlFor="companyName" data-testid="companyName">
                    <FormattedMessage id="RecurlyPaymentForm.Company" />
                  </label>
                  <FormikField
                    data-recurly="company"
                    type="text"
                    name="companyName"
                    id="companyName"
                  />
                </Form.Field>
              )}
              <Form.Group widths="equal">
                <Form.Field error={touched.address1 && errors.address1}>
                  <label htmlFor="address1" data-testid="address1">
                    <FormattedMessage id="RecurlyPaymentForm.address1" />
                  </label>
                  <FormikField
                    data-recurly="address1"
                    type="text"
                    name="address1"
                    id="address1"
                  />
                  {touched.address1 && errors.address1 && (
                    <div>{errors.address1}</div>
                  )}
                </Form.Field>
                <Form.Field error={touched.address2 && errors.address2}>
                  <label htmlFor="address2" data-testid="address2">
                    <FormattedMessage id="RecurlyPaymentForm.address2" />
                  </label>
                  <FormikField
                    data-recurly="address2"
                    type="text"
                    name="address2"
                    id="address2"
                  />
                  {touched.address2 && errors.address2 && (
                    <div>{errors.address2}</div>
                  )}
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field error={touched.city && errors.city}>
                  <label htmlFor="city" data-testid="city">
                    <FormattedMessage id="RecurlyPaymentForm.city" />
                  </label>
                  <FormikField
                    data-recurly="city"
                    type="text"
                    name="city"
                    id="city"
                  />
                  {touched.city && errors.city && <div>{errors.city}</div>}
                </Form.Field>
                <Form.Field error={touched.postal_code && errors.postal_code}>
                  <label htmlFor="postal_code" data-testid="postal_code">
                    <FormattedMessage id="RecurlyPaymentForm.postal_code" />
                  </label>
                  <FormikField name="postal_code">
                    {({
                      field, // { name, value, onChange, onBlur }
                    }) => (
                      <input
                        {...field}
                        data-recurly="postal_code"
                        type="text"
                        name="postal_code"
                        id="postal_code"
                        value={values.postal_code}
                        onChange={event => {
                          field.onChange(event);
                          this.props.onPostalCodeChange(event.target.value);
                        }}
                      />
                    )}
                  </FormikField>
                  {touched.postal_code && errors.postal_code && (
                    <Message error data-testid="postal-code-error">
                      <FormattedMessage id="RecurlyPaymentForm.Errors.PostCode" />
                    </Message>
                  )}
                </Form.Field>

                {!isUnitedStates(values.country) && (
                  <Form.Field error={touched.state && errors.state}>
                    <label htmlFor="state" data-testid="state">
                      <FormattedMessage id="RecurlyPaymentForm.state" />
                    </label>
                    <FormikField
                      data-recurly="state"
                      type="text"
                      name="state"
                      id="state"
                    />
                    {touched.state && errors.state && <div>{errors.state}</div>}
                  </Form.Field>
                )}
                {isUnitedStates(values.country) && (
                  <Form.Field error={touched.usState && errors.usState}>
                    <label htmlFor="usState" data-testid="us-state">
                      <FormattedMessage id="RecurlyPaymentForm.state" />
                    </label>
                    <FormikField
                      className="ui dropdown"
                      component="select"
                      name="usState"
                      id="usState"
                      data-recurly="state"
                    >
                      {/* eslint-disable react/jsx-no-literals */}
                      <option value="">
                        {intl.formatMessage({
                          id: 'RecurlyPaymentForm.Select',
                        })}
                      </option>
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AS">America Samoa</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="AA">Armed Forces Americas</option>
                      <option value="AE">Armed Forces</option>
                      <option value="AP">Armed Forces Pacific</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District of Columbia</option>
                      <option value="FM">Federated States of Micronesia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="GU">Guam</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MH">Marshall Islands</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="MP">Northern Mariana Islands</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="PR">Puerto Rico</option>
                      <option value="PW">Palau</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VA">Virginia</option>
                      <option value="VI">Virgin Islands</option>
                      <option value="VT">Vermont</option>
                      <option value="WA">Washington</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WV">West Virginia</option>
                      <option value="WY">Wyoming</option>

                      {/* eslint-enable react/jsx-no-literals */}
                    </FormikField>
                    {touched.usState && errors.usState && (
                      <div>{errors.usState}</div>
                    )}
                  </Form.Field>
                )}
              </Form.Group>
              <h3>
                <FormattedMessage id="RecurlyPaymentForm.CardSectionTitle" />
              </h3>
              <Form.Group widths="equal">
                <Form.Field error={touched.firstName && errors.firstName}>
                  <label htmlFor="firstName">
                    <FormattedMessage id="RecurlyPaymentForm.FirstName" />
                  </label>
                  <FormikField
                    data-recurly="first_name"
                    type="text"
                    name="firstName"
                    id="firstName"
                  />
                  {touched.firstName && errors.firstName && (
                    <div>{errors.firstName}</div>
                  )}
                </Form.Field>
                <Form.Field error={touched.lastName && errors.lastName}>
                  <label htmlFor="lastName">
                    <FormattedMessage id="RecurlyPaymentForm.lastName" />
                  </label>
                  <FormikField
                    data-recurly="last_name"
                    type="text"
                    name="lastName"
                    id="lastName"
                  />
                  {touched.lastName && errors.lastName && (
                    <div>{errors.lastName}</div>
                  )}
                </Form.Field>
              </Form.Group>
              <div
                id="recurly-elements"
                className={classNames('recurly-elements mb-3', {
                  'recurly-elements-error':
                    errors.cardExpiring || errors.card || errors.cvv,
                })}
              />
              {errors.card && (
                <Message error>
                  <FormattedMessage id="RecurlyPaymentForm.card" />
                </Message>
              )}
              {errors.cvv && (
                <Message error data-testid="cvv-error">
                  <FormattedMessage
                    id="RecurlyPaymentForm.Errors.CVV"
                    values={{ length: errors.cvv.length }}
                  />
                </Message>
              )}
              <input type="hidden" name="recurly-token" data-recurly="token" />
              <div className="flex center space-between">
                <Button
                  primary
                  loading={isSubmitting || isAddingSubscription}
                  disabled={isSubmitting || isAddingSubscription}
                  type="submit"
                  data-testid="submit-payment-form"
                >
                  {submitButtonText}
                </Button>
                {dismissButton}
              </div>
            </form>
          )}
        />
      </PageTransition>
    );
  }
}

RecurlyPaymentForm.propTypes = {
  intl: intlShape.isRequired,
  plan: PropTypes.string,
  quantity: PropTypes.number,
  billingInformation: PropTypes.object.isRequired,
  onRecurlyTokenReceived: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  isAddingSubscription: PropTypes.bool.isRequired,
  onSelectedCountryCodeChange: PropTypes.func.isRequired,
  onPostalCodeChange: PropTypes.func.isRequired,
  onVatNumberChange: PropTypes.func.isRequired,
  countryCode: PropTypes.string,
  locale: PropTypes.string,
  dismissButton: PropTypes.node,
  lightScheme: PropTypes.bool,
};

export default connect(state => ({
  locale: getLocale(state),
}))(injectIntl(withPrefersColorScheme(RecurlyPaymentForm)));
